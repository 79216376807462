import { useEffect, useState } from "react";
// redux
import { useDispatch, useSelector } from "react-redux";
// forms
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
// redux
import { updatePassword, updateLostPassword, updatePasswordV2 } from "src/features/userSlice";
// components
import { ToastSuccess } from 'src/components/Toast';
import LoadingButton from 'src/components/LoadingButton';
// MUI
import {
  IconButton,
  InputAdornment,
  TextField,
  Grid,
  Box,
  Typography,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

// ----------------------------------------------------------------------

export default function ResetPasswordForm({ user }) {
  const loginRes = JSON.parse(localStorage.getItem("loginRes"));
  const [showPassword, setShowPassword] = useState(false);
  const [showCurPassword, setShowCurPassword] = useState(false);
  const { userUpdateLoading } = useSelector((state) => state.user);

  useEffect(() => {
    if (!user) {
      setValue("email", loginRes.userName);
    } else {
      setValue("email", user.email);
    }
  }, [user, loginRes]);

  const dispatch = useDispatch();

  const formSchema = Yup.object().shape({
    password: Yup.string()
      .required("Current password is required"),
    setpassword: Yup.string()
      .required("Please enter new password"),
    newPassword: Yup.string()
      .required("Confirm Password is required")
      .min(8, "Password must be at least 8 characters")
      .oneOf([Yup.ref("setpassword")], "Passwords do not match")
  });

  const formSchema_New = Yup.object().shape({
    setpassword: Yup.string()
      .required("Please enter new password"),
    newPassword: Yup.string()
      .required("Confirm Password is required")
      .min(8, "Password must be at least 8 characters")
      .oneOf([Yup.ref("setpassword")], "Passwords do not match")
  });

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onTouched",
    resolver: !!user ? yupResolver(formSchema) : yupResolver(formSchema_New),
    defaultValues: {
      email: '',
      password: '',
      setpassword: '',
      newPassword: '',
    }
  });

  const onSubmit = (data) => {
    if (user) {
      const updateData = {
        id: user.userSK,
        data: {
          password: data.password,
          newPassword: data.newPassword
        }
      }

      dispatch(updatePasswordV2(updateData)).then((res) => {
        if (res.payload?.data.userSK) {
          ToastSuccess('Password saved');
          reset();
        }
      });
    } else {
      const updateData = {
        id: loginRes?.userId,
        data: {
          password: data.password,
          newPassword: data.newPassword
        }
      }

      dispatch(updatePasswordV2(updateData)).then((res) => {
        if (res.payload?.data.userSK) {
          ToastSuccess('Password saved');
          reset();
        }
      });
    }
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item sm={12} md={12}>
          <Box sx={{ display: 'flex', alignItems: 'start', flexDirection: 'column' }}>
            <Typography variant="body1" fontWeight="bold">
              {user ? user.email : loginRes.userName}
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item sm={12} md={6}>
          <Controller
            name='password'
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                error={!!error}
                helperText={error?.message}
                label={user ? "Admin Password" : "Current Password"}
                type={showCurPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowCurPassword(!showCurPassword)} edge="end">
                        {showCurPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item sm={12} md={6}>
          <Controller
            name='setpassword'
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                error={!!error}
                helperText={error?.message}
                label="New Password"
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <Controller
            name='newPassword'
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                error={!!error}
                helperText={error?.message}
                label="Confirm New Password"
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item md={12}>
          <Box sx={{ display: 'flex', justifyContent: 'end' }}>
            <LoadingButton
              type="submit"
              variant="contained"
              color="primary"
              loading={userUpdateLoading}
              sx={{ width: '110px' }}
            >
              Save
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>
    </form >
  );
}
