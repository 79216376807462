import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// MUI
import {
    Box, Card, CardContent, Typography,
    Divider, MenuList, MenuItem, ListItemText, Button, Menu,
} from "@mui/material";

// redux
import { getCompanies } from "../../features/companySlice";
import { getEquipmentByIdV2, clearEquipmentById } from "../../features/equipmentSlice";
import { getAllSystemInfo, createSystemInfo, assignEquipment } from "../../features/systemInfoSlice";
import { iconSet } from "../../@core/data/icons";
import { ToastSuccess, ToastError } from "../../components/Toast";
import PageBreadcrumbs from "src/components/PageBreadcrumbs";
import EquipmentForm from "./components/EquipmentForm";
import EquipmentDisplay from "./components/EquipmentDisplay";
import SystemInfoForm from "src/pages/system-info/components/SystemInfoForm";
import LoadingButton from 'src/components/LoadingButton';

// ----------------------------------------------------------------------

const breadcrumbs = [
    { route: "/home", label: "Home" },
    { route: "/equipment", label: "Equipment" },
    { route: "", label: "Detail" },
];

// ----------------------------------------------------------------------

export default function EquipmentFormPage() {
    const loginRes = JSON.parse(localStorage.getItem("loginRes"));
    const isSuperAdmin = loginRes?.superAdmin;
    const dispatch = useDispatch();
    const [submitting, setSubmitting] = useState(false);
    const { equipmentById } = useSelector((state) => state.equipment);
    const { systemInfos } = useSelector((state) => state.systemInfo);
    const params = new URLSearchParams(window.location.search);
    const paramsId = params.get("id");
    const canEditEquipment = !!isSuperAdmin;

    useEffect(() => {
        if (isSuperAdmin) {
            dispatch(getCompanies());
            dispatch(getAllSystemInfo());
        }

        if (paramsId) {
            dispatch(getEquipmentByIdV2(paramsId));
        } else {
            dispatch(clearEquipmentById());
        }
    }, [paramsId, dispatch]);

    const addSystemToEquipment = () => {
        const data = {
            equipmentSK: paramsId,
        };

        setSubmitting(true);

        dispatch(createSystemInfo(data)).then((res) => {
            setSubmitting(false);
            ToastSuccess("System added");
            dispatch(getEquipmentByIdV2(paramsId));
        });
    }

    const assignSystemInfoToEquipment = (systemId) => {
        const data = {
            id: systemId,
            data: {
                equipmentSK: paramsId,
            }
        };

        dispatch(assignEquipment(data)).then((res) => {
            ToastSuccess("System assigned to equipment");
            dispatch(getEquipmentByIdV2(paramsId));
        });
    }

    const removeSystemInfoFromEquipment = (systemId) => {
        const data = {
            id: systemId,
            data: {
                equipmentSK: null,
            }
        };

        dispatch(assignEquipment(data)).then((res) => {
            ToastSuccess("System removed");
            dispatch(getEquipmentByIdV2(paramsId));
        });
    }

    const onSystemMenuClick = (systemId) => {
        if (systemId === 'new') {
            addSystemToEquipment();
        } else {
            assignSystemInfoToEquipment(systemId);
        }
    }

    return (
        <Box className="d-flex flex-column px-md-4 form-layout customer-form">
            <Box className="d-flex mb-2 mt-2">
                <Box className="flex-grow-1">
                    <PageBreadcrumbs pageName="Equipment Detail" breadcrumbs={breadcrumbs} icon={iconSet.equipment} />
                </Box>
            </Box>

            <Box>
                {isSuperAdmin && (
                    <Card>
                        <CardContent>
                            <Typography sx={{ mb: 2 }} variant="h5" gutterBottom>Equipment</Typography>

                            <EquipmentForm />
                        </CardContent>
                    </Card>
                )}
                {!isSuperAdmin && (
                    <EquipmentDisplay equipment={equipmentById} />
                )}

                {(isSuperAdmin && equipmentById?.equipmentSK && !equipmentById.systemInfo) && (
                    <Card sx={{ mt: 2 }}>
                        <CardContent>
                            <Box sx={{ marginTop: '10px' }}>
                                <SystemInfoMenu handleMenuClick={onSystemMenuClick} systemInfos={systemInfos} />
                            </Box>
                        </CardContent>
                    </Card>
                )}

                {(equipmentById?.systemInfo) && (
                    <Card sx={{ mt: 2 }}>
                        <CardContent>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography sx={{ mb: 2 }} variant="h5" gutterBottom>System</Typography>

                                {isSuperAdmin && (
                                    <Button variant="contained" color="primary" onClick={() => removeSystemInfoFromEquipment(equipmentById.systemInfo.id)}>
                                        Remove System
                                    </Button>
                                )}
                            </Box>

                            <SystemInfoForm id={equipmentById.systemInfo.id} />
                        </CardContent>
                    </Card>
                )}
            </Box>
        </Box>
    );
};

const SystemInfoMenu = ({ handleMenuClick, systemInfos }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleButtonClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (option) => {
        handleMenuClick(option);
        handleClose();
    }

    return (
        <Box>
            <Button variant="contained" color="primary" onClick={handleButtonClick}>
                Add System
            </Button>

            <Menu
                sx={{ width: 320 }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuList dense>
                    <MenuItem onClick={() => handleMenuItemClick('new')}>
                        <ListItemText>Add New System</ListItemText>
                    </MenuItem>


                    {systemInfos && systemInfos.length > 0 && (
                        <Divider />
                    )}

                    {systemInfos && systemInfos.map((systemInfo) => (
                        <MenuItem key={systemInfo.id} onClick={() => handleMenuItemClick(systemInfo.id)}>
                            <ListItemText>{systemInfo.name ? systemInfo.name : '<No Name>'}</ListItemText>
                        </MenuItem>
                    ))}
                </MenuList>
            </Menu>
        </Box>
    );
}