// @mui
import { CssBaseline } from '@mui/material';
import {
  createTheme,
  ThemeProvider as MUIThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles';
//import componentsOverride from './override';

// ----------------------------------------------------------------------

export default function ThemeProvider(props) {
  const { children } = props;

  const themeOptions = {
    palette: {
      mode: 'light',
      primary: {
        main: '#17a2b8',
      },
      secondary: {
        main: '#1B5330',
      },
      grey: {
        100: '#F4F4F5',
        200: '#EAEAEC',
        300: '#DFDFE2',
        400: '#D4D4D8',
        500: '#C9C9CF',
        600: '#BEBEC5',
        700: '#B3B3BC',
        800: '#A9A9B2',
        900: '#9E9EA9',
      },
      success: {
        100: '#DAECD4',
        200: '#CDE6C6',
        300: '#C0E0B8',
        400: '#B4DAA9',
        500: '#A7D49B',
        600: '#9BCE8D',
        700: '#8EC87E',
        800: '#82C270',
        900: '#75BC62',
      },
      error: {
        100: '#ED8282',
        200: '#EB7070',
        300: '#E85E5E',
        400: '#E64C4C',
        500: '#E54B4B',
        600: '#E33B3B',
        700: '#E02929',
        800: '#D61F1F',
        900: '#C41C1C',
      },
      warning: {
        light: '#FFAF47',
        main: '#FE9000',
        dark: '#E07F00',
        contrastText: '#fff',
      },
      text: {
        rtd: '#1c5230',
      }
    },
  }

  const theme = createTheme(themeOptions);
  //theme.components = componentsOverride();

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}