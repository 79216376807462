import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ToastSuccess, ToastError } from "../components/Toast";
import { axiosApiInstance } from '../@core/utils/axios';

const initialState = {
    loading: false,
    allCompanies: [],
    companyById: [],
    createdCompany: {},
    formSuccess: [],
    error: '',
    adminSelectedCompany: { companyName: '', companyId: 0 },
};

export const getAllCompanyList = createAsyncThunk(
    'company/getAllCompanyList',
    async (loginIds) => {
        try {
            const { data } = await axiosApiInstance.get(`/Company/GetCompany?companyId=${loginIds.companyId}&userId=${loginIds.userId}`);
            return data;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);

export const getCompanyListForHeader = createAsyncThunk(
    'company/getCompanyListForHeader',
    async (loginIds) => {
        try {
            const { data } = await axiosApiInstance.get(`/Company/GetCompany?companyId=${loginIds.companyId}&userId=${loginIds.userId}`);
            return data;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);

export const postCompanyList = createAsyncThunk(
    'company/postCompanyList',
    async (data) => {
        try {
            const loginId = JSON.parse(localStorage.getItem("loginRes"));
            const response = await axiosApiInstance.post(`/Company/Save?userId=${loginId.userId}`, data);
            return response;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);

// V2

export const getCompanies = createAsyncThunk(
    'company/getCompanies',
    async () => {
        try {
            const { data } = await axiosApiInstance.get(`/api/v2/companies`);
            return data;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);

export const getCompanyById = createAsyncThunk(
    'company/getCompanyById',
    async (id) => {
        try {
            const { data } = await axiosApiInstance.get(`/api/v2/companies/${id}`);
            return data;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);

export const createCompany = createAsyncThunk(
    'company/createCompany',
    async (data) => {
        try {
            const response = await axiosApiInstance.post(`/api/v2/companies`, data);
            return response;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);

export const updateCompany = createAsyncThunk(
    'company/updateCompany',
    async (data) => {
        try {
            const url = `/api/v2/companies/${data.companySK}`;
            const response = await axiosApiInstance.put(url, data.postData);
            return response;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);

export const setAdminSelectedCompany = createAsyncThunk(
    'company/setAdminSelectedCompany',
    async (data) => {
        return data;
    }
);

export const companySlice = createSlice({
    name: 'company',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getCompanies.pending, (state) => {
                state.loading = true;
            })
            .addCase(getCompanies.fulfilled, (state, action) => {
                state.loading = false;
                state.allCompanies = action.payload || [];
            })
            .addCase(getCompanies.rejected, (state, action) => {
                state.loading = false;
                state.allCompanies = [];
                state.allCompanies = action.error.message;
            })

            .addCase(getCompanyById.pending, (state) => {
                state.loading = true;
            })
            .addCase(getCompanyById.fulfilled, (state, action) => {
                state.loading = false;
                state.companyById = action.payload || [];
            })
            .addCase(getCompanyById.rejected, (state, action) => {
                state.loading = false;
                state.companyById = [];
                state.companyById = action.error.message;
            })

            .addCase(createCompany.pending, (state) => {
                state.loading = true;
            })
            .addCase(createCompany.fulfilled, (state, action) => {
                state.loading = false;
                state.formSuccess = action.payload;
            })
            .addCase(createCompany.rejected, (state, action) => {
                state.loading = false;
                state.formSuccess = [];
                state.formSuccess = action.error.message;
            })

            .addCase(updateCompany.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateCompany.fulfilled, (state, action) => {
                state.loading = false;
                state.formSuccess = action.payload;
            })
            .addCase(updateCompany.rejected, (state, action) => {
                state.loading = false;
                state.formSuccess = [];
                state.formSuccess = action.error.message;
            })

            .addCase(setAdminSelectedCompany.fulfilled, (state, action) => {
                state.adminSelectedCompany = action.payload;
            })

            // V1
            .addCase(getAllCompanyList.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllCompanyList.fulfilled, (state, action) => {
                state.loading = false;
                const { result } = action.payload;
                state.allCompanies = result?.company || [];
            })
            .addCase(getAllCompanyList.rejected, (state, action) => {
                state.loading = false;
                state.allCompanies = [];
                state.allCompanies = action.error.message;
            })
            .addCase(postCompanyList.pending, (state) => {
                state.loading = true;
            })
            .addCase(postCompanyList.fulfilled, (state, action) => {
                state.loading = false;
                state.formSuccess = action.payload;
            })
            .addCase(postCompanyList.rejected, (state, action) => {
                state.loading = false;
                state.formSuccess = [];
                state.formSuccess = action.error.message;
            });
    },
});

export default companySlice.reducer;
