// MUI
import {
    Box,
    Dialog, DialogTitle, DialogContent
} from '@mui/material';
// components
import SensorForm from './SensorForm';

// ----------------------------------------------------------------------

export default function SensorFormDialog({ open, onClose, sensor, systemInfoId }) {
    const onSaved = () => {
        onClose();
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth={true}
            maxWidth="sm"
        >
            <DialogTitle sx={{ mb: 1 }}>{!!sensor ? 'Update' : 'Add'} Sensor</DialogTitle>
            <DialogContent>
                <Box sx={{ pt: 1 }}>
                    <SensorForm sensor={sensor} onSaved={onSaved} systemInfoId={systemInfoId} />
                </Box>
            </DialogContent>
        </Dialog>
    );
}
