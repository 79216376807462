// MUI
import {
    Box, Card, CardContent, Typography,
} from "@mui/material";
// redux
import { iconSet } from "../../@core/data/icons";
import PageBreadcrumbs from "src/components/PageBreadcrumbs";
import SystemInfoForm from "src/pages/system-info/components/SystemInfoForm";

// ----------------------------------------------------------------------

const breadcrumbs = [
    { route: "/home", label: "Home" },
    { route: "/systems", label: "Systems" },
    { route: "", label: "Detail" },
];

// ----------------------------------------------------------------------

export default function SystemFormPage() {
    const params = new URLSearchParams(window.location.search);
    const paramsId = params.get("id");

    return (
        <Box className="d-flex flex-column px-md-4 form-layout customer-form">
            <Box className="d-flex mb-2 mt-2">
                <Box className="flex-grow-1">
                    <PageBreadcrumbs pageName="System Detail" breadcrumbs={breadcrumbs} icon={iconSet.system} />
                </Box>
            </Box>

            <Box>
                <Card sx={{ mt: 2 }}>
                    <CardContent>
                        <Typography sx={{ mb: 3 }} variant="h5" gutterBottom>System</Typography>

                        <SystemInfoForm id={paramsId} />
                    </CardContent>
                </Card>
            </Box>
        </Box>
    );
};

