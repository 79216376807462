import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from "@mui/material/ListItemText";
import { permission } from "../../@core/data/permission";

const NavListItem = ({ pagename, pageurl, iconname, rolesPermission, switchCompany, onClick }) => {
  const navigate = useNavigate();
  const loginRes = JSON.parse(localStorage.getItem("loginRes"));
  const { rolesloading, roles } = useSelector((state) => state.login);
  const isSuperAdmin = loginRes?.superAdmin;

  const roleAllowed = !rolesloading && roles.userCompanyRolePermissionInfo.some((rolscheck) => {
    return rolscheck.permissionName === rolesPermission && (
      rolscheck.isWrite || rolscheck.isRead || rolscheck.isDelete
    );
  });

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    
    navigate(pageurl);
  }

  if(rolesPermission === permission.customer){
    return !isSuperAdmin && (
      <ListItemButton key={pagename} className="listnav-bg" onClick={handleClick}>
        <span className="material-symbols-outlined">{iconname}</span>
        <ListItemText primary={pagename} className="listnav-bg-txt" />
      </ListItemButton>
    );
  } else if (!isSuperAdmin && switchCompany) {
    return (
      <ListItemButton className="listnav-bg" onClick={handleClick}>
        <span className="material-symbols-outlined">{iconname}</span>
        <ListItemText primary={pagename} className="listnav-bg-txt" />
      </ListItemButton>
    );
  } else if (isSuperAdmin && switchCompany !== null) {
    return (
      <ListItemButton className="listnav-bg" onClick={handleClick}>
        <span className="material-symbols-outlined">{iconname}</span>
        <ListItemText primary={pagename} className="listnav-bg-txt" />
      </ListItemButton>
    );
  } else {
    return roleAllowed && (
      <ListItemButton key={pagename} className="listnav-bg" onClick={handleClick}>
        <span className="material-symbols-outlined">{iconname}</span>
        <ListItemText primary={pagename} className="listnav-bg-txt" />
      </ListItemButton>
    );
  }
}

export default NavListItem;