import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
// redux
import { useDispatch } from "react-redux";
import { 
  loginV2,
  setLoggedIn,
  setLoginIds,
} from "src/features/loginSlice";
// assets
import logoRTD from "src/assets/image/rtd-logo-1080.png";
// MUI
import { styled } from '@mui/material/styles';
import {
  InputAdornment, 
  TextField, 
  IconButton, 
  Box, 
  Grid, 
  Typography,
  Card, 
  CardContent,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
// components
import { ToastError } from "src/components/Toast";
import LoadingButton from "src/components/LoadingButton";
// util
import { setLoginStorage } from "src/@core/utils/loginHelper";

// ----------------------------------------------------------------------

const LeftContainerBox = styled(Box)(({ theme }) => ({
  maxWidth: '400px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: '100%',

  [theme.breakpoints.down('md')]: {
    maxWidth: '500px',
    alignItems: 'center',
    width: '100%',
    margin: 'auto'
  },

  [theme.breakpoints.down('sm')]: {
    maxWidth: '300px',
    alignItems: 'center',
    width: '100%',
    margin: 'auto'
  },
}));

const LoginCard = styled(Card)(({ theme }) => ({
  padding: '20px',
  marginLeft: '50px',
  maxWidth: '450px',
  width: '100%',


  [theme.breakpoints.down('lg')]: {
    maxWidth: '380px',
  },

  [theme.breakpoints.down('md')]: {
    maxWidth: '500px',
    alignItems: 'center',
    width: '100%',
    margin: 'auto',
    marginTop: '20px',
  },

  [theme.breakpoints.down('sm')]: {
    maxWidth: '300px',
    alignItems: 'center',
    width: '100%',
    margin: 'auto',
    marginTop: '20px',
  },
}));


const TaglineContainer = styled(Typography)(({ theme }) => ({
  color: '#494949',
  marginLeft: '12px',
  fontSize: '1.15em',

  [theme.breakpoints.down('md')]: {
    textAlign: 'center',
  },
}));


// ----------------------------------------------------------------------

export default function LoginPage() {
  const { register, handleSubmit } = useForm();
  const [submitting, setSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    setSubmitting(true);

    dispatch(loginV2(data)).then(response => {
      setSubmitting(false);

      if (!response.payload) {
        return;
      }

      const loginRes = response.payload.data;

      if (!loginRes?.userId) {
        ToastError('An unknown error occurred and we could not log you in. Please contact support.');
        return;
      } else if (loginRes.companySk == null && !loginRes.isSuperAdmin) {
        ToastError("A company is not been assigned to your account. Please contact support.");
        return;
      }
      
      setLoginStorage(loginRes);
      const loginResStorage = JSON.parse(localStorage.getItem("loginRes"));

      dispatch(setLoginIds(loginResStorage));
      dispatch(setLoggedIn(true));
      // Auth guard should now redirect from the updated loggedIn state
    });
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <LeftContainerBox>
              <Box>
                <Box component="img" src={logoRTD} alt="RTDensity" sx={myStyle.logoImage} />
              </Box>
              <TaglineContainer component="div" variant="subtitle">
                No more guesswork during the compaction process!
              </TaglineContainer>
            </LeftContainerBox>
          </Grid>

          <Grid item xs={12} md={6}>
            <LoginCard>
              <CardContent>
                <Box sx={{ mb: 3 }}>
                  <Typography variant="h5">LOGIN</Typography>
                </Box>

                <TextField 
                  fullWidth 
                  sx={{ mb: 2 }} 
                  type="email" 
                  label="Email" {...register("email")} 
                />

                <TextField
                  fullWidth
                  sx={{ mb: 2 }}
                  label="Password"
                  type={showPassword ? 'text' : 'password'}
                  {...register("password")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                          {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <LoadingButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  loading={submitting}
                  sx={{ width: '110px' }}
                >
                  Login
                </LoadingButton>
              </CardContent>
            </LoginCard>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

const myStyle = {
  logoImage: {
    maxWidth: '400px',
    width: '100%',
  },
}
