import { format, parse, parseISO } from 'date-fns';
import { formatInTimeZone  } from 'date-fns-tz';

export function formatIsoDate(dateSt, formatStr = 'MMM. d, yyyy HH:mm a') {
    if (!dateStr) return '';

    try {
        if (dateStr instanceof Date) {
            const formattedDate = format(dateStr, formatStr);
            return formattedDate;
        }

        const parsedDate = parseISO(dateStr);
        const formattedDate = format(parsedDate, formatStr);

        return formattedDate;
    }catch (error) {
        console.error('Error formatting date: ', dateStr, ' error: ', error);
        return '';
    }
}

export function formatDate(inputDate, inputFormat, outputFormat = 'MMM. d, yyyy HH:mm a') {
    if (!inputDate) return '';

    try {
        if (inputDate instanceof Date) {
            const formattedDate = format(inputDate, outputFormat);
            return formattedDate;
        }

        let parsedDate;
        if (inputDate.includes('T')) {
            parsedDate = parseISO(inputDate); // Parse as ISO with or without fractional seconds
        } else {
            parsedDate = parse(inputDate, inputFormat, new Date()); // Fallback to parsing using the input format
        }

        const formattedDate = format(parsedDate, outputFormat);
        return formattedDate;
    } catch (error) {
        console.error(`Error formatting date: "${inputDate}". Error: ${error}`);
        return '';
    }
}

export function formatUtcToLocalTz(dateStr, outputFormat = 'MMM. d, yyyy h:mm a') {
    if (!dateStr) return '';

    try {
        if (!dateStr.endsWith('Z')) {
            dateStr += 'Z'; // Append 'Z' to ensure the date string is treated as UTC
        }

        const timeZone = getUserTimeZone();
        const parsedDate = parseISO(dateStr);
        const formattedDate = formatInTimeZone(parsedDate, timeZone, outputFormat);

        return formattedDate;
    } catch (error) {
        console.error('Error formatting date to local timezone: ', dateStr, ' error: ', error);
        return '';
    }
}

export function getUserTimeZone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
}