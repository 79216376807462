import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ToastSuccess, ToastError } from "../components/Toast";
import { axiosApiInstance } from "../@core/utils/axios";

const initialState = {
  tabletListLoading: false,
  tabletRowLoading: false,
  tabletIotLoading: false,
  iotDevicesByTabletIdLoading: false,
  iotDevicesByTabletId: [],
  allTabletdevice: [],
  tabletdeviceById: [],
  tabletIotRelation: [],
    formSuccess: [],
    configSuccess: [],
    allTabletdeviceByCompanyLoading: [],
    allTabletdeviceByCompany:[],
    error: "",
    formAddSuccess: [],
    configiotSuccess:[]
};

export const getAllTabletDevices = createAsyncThunk(
  "tablet/getAllTabletDevices",
  async (loginIds) => {
    try {
      const { data } = await axiosApiInstance.get(
        `/Tablet/GetAllTablet?companyId=${loginIds.companyId}&userId=${loginIds.userId}`
      );
      return data;
    } catch (error) {
      ToastError(error.response.data.messageKey);
    }
  }
);

export const getTabletDevicesByCompany = createAsyncThunk(
    "iot/getTabletDevicesByCompany",
    async (loginIds) => {
        try {
            
            const { data } = await axiosApiInstance.get(
                `/Tablet/GetAllTabletCompany?companyId=${loginIds.companyId}&userId=${loginIds.userId}`
            );
            return data;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);


export const getAllAvailableTabletDevices = createAsyncThunk(
    "tablet/getAllAvailableTabletDevices",
    async (loginIds) => {
        try {
            const { data } = await axiosApiInstance.get(
                `/Tablet/GetAllAvailableTablet?userId=${loginIds.userId}`
            );
            return data;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);

export const getTabletIotRelation = createAsyncThunk(
  "project/getTabletIotRelation",
  async (loginIds) => {
    try {
      const { data } = await axiosApiInstance.get(
        `/Tablet/GetTabletIotRelation?tabletSK=${loginIds.tabletId}&companyId=${loginIds.companyId}&userId=${loginIds.userId}`
      );
      return data;
    } catch (error) {
      ToastError(error.response.data.messageKey);
    }
  }
);

export const getTabletDeviceById = createAsyncThunk(
  "tablet/getTabletDeviceById",
  async (loginIds) => {
    try {
      const { data } = await axiosApiInstance.get(
        `/Tablet/GetTablet?tabletId=${loginIds.rowId}&companyId=${loginIds.companyId}&userId=${loginIds.userId}`
      );
      return data;
    } catch (error) {
      ToastError(error.response.data.messageKey);
    }
  }
);

export const getIOTDevicesByTabletId = createAsyncThunk(
  "tablet/getIOTDevicesByTabletId",
  async (loginIds) => {
    try {
      console.log("loginIds", loginIds);
      const { data } = await axiosApiInstance.get(
        `/Tablet/GetAllTabletIOTDevice?companyId=${loginIds.companyId}&tabletSK=${loginIds.rowId}&c&userId=${loginIds.userId}`
      );
      return data;
    } catch (error) {
      ToastError(error.response.data.messageKey);
    }
  }
);

export const postTabletDevice = createAsyncThunk(
  "tablet/postTabletDevice",
  async (data) => {
    try {
      const loginId = JSON.parse(localStorage.getItem("loginRes"));
      const response = await axiosApiInstance.post(
        `/Tablet/Save?userId=${loginId.userId}`,
        data
      );
      return response;
    } catch (error) {
      ToastError(error.response.data.messageKey);
    }
  }
);

export const postTabletConfig = createAsyncThunk(
    "iot/postTabletConfig",
    async (data) => {
        try {
            const loginId = JSON.parse(localStorage.getItem("loginRes"));
            const response = await axiosApiInstance.post(
                `/Tablet/AssignTabletCompany?userId=${loginId.userId}`,
                data
            );
            return response;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);

export const postAvailableTabletDevice = createAsyncThunk(
    "tablet/postAvailableTabletDevice",
    async (data) => {
        try {
            const loginId = JSON.parse(localStorage.getItem("loginRes"));
            const response = await axiosApiInstance.post(
                `/Tablet/SaveAvailableTablet?userId=${loginId.userId}`,
                data
            );
            return response;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);

export const postIOTDeviceByTabletId = createAsyncThunk(
  "tablet/postIOTDeviceByTabletId",
  async (data) => {
    try {
      const loginId = JSON.parse(localStorage.getItem("loginRes"));
      const response = await axiosApiInstance.post(
          `/Tablet/AssignIOTTablet?tabletSK=${data.tabletSK}&userId=${loginId.userId}`,
        data
      );
      return response;
    } catch (error) {
      ToastError(error.response.data.messageKey);
    }
  }
);

export const tabletDeviceSlice = createSlice({
  name: "tablet",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllTabletDevices.pending, (state) => {
        state.tabletListLoading = true;
      })
      .addCase(getAllTabletDevices.fulfilled, (state, action) => {
        state.tabletListLoading = false;
        const { result } = action.payload;
        state.allTabletdevice = result?.tablets || [];
      })
      .addCase(getAllTabletDevices.rejected, (state, action) => {
        state.tabletListLoading = false;
        state.allTabletdevice = [];
        state.allTabletdevice = action.error.message;
      })
        .addCase(getAllAvailableTabletDevices.fulfilled, (state, action) => {
            state.availableTabletListLoading = false;
            const { result } = action.payload;
            state.allAvailableTabletdevice = result?.tablets || [];
      })
        .addCase(getAllAvailableTabletDevices.rejected, (state, action) => {
            state.availableTabletListLoading = false;
            state.allAvailableTabletdevice = [];
            state.allAvailableTabletdevice = action.error.message;
      })
        .addCase(getAllAvailableTabletDevices.pending, (state) => {
            state.availableTabletListLoading = true;
      })
      .addCase(getTabletDeviceById.pending, (state) => {
        state.tabletRowLoading = true;
      })
      .addCase(getTabletDeviceById.fulfilled, (state, action) => {
        state.tabletRowLoading = false;
        const { result } = action.payload;
        state.tabletdeviceById = result?.tablets || [];
      })
      .addCase(getTabletDeviceById.rejected, (state, action) => {
        state.tabletRowLoading = false;
        state.tabledeviceById = [];
        state.tabledeviceById = action.error.message;
      })
      .addCase(getTabletIotRelation.pending, (state) => {
        state.tabletIotLoading = true;
      })
      .addCase(getTabletIotRelation.fulfilled, (state, action) => {
        state.tabletIotLoading = false;
        const { result } = action.payload;
        state.tabletIotRelation = result?.tablets || [];
      })
      .addCase(getTabletIotRelation.rejected, (state, action) => {
        state.tabletIotLoading = false;
        state.tabledeviceById = [];
        state.tabletIotRelation = action.error.message;
      })
      .addCase(postTabletDevice.fulfilled, (state, action) => {
        state.formSuccess = action.payload;
      })
      .addCase(postTabletDevice.rejected, (state, action) => {
        state.formSuccess = [];
        state.formSuccess = action.error.message;
      })
        .addCase(postAvailableTabletDevice.fulfilled, (state, action) => {
              state.formAddSuccess = action.payload;
          })
        .addCase(postAvailableTabletDevice.rejected, (state, action) => {
            state.formAddSuccess = [];
            state.formAddSuccess = action.error.message;
          })

      
        .addCase(postTabletConfig.fulfilled, (state, action) => {
            state.configSuccess = action.payload;
        })
        .addCase(postTabletConfig.rejected, (state, action) => {
            state.configSuccess = [];
            state.configSuccess = action.error.message;
        })

        .addCase(postIOTDeviceByTabletId.fulfilled, (state, action) => {
            state.configiotSuccess = action.payload;
        })
        .addCase(postIOTDeviceByTabletId.rejected, (state, action) => {
            state.configiotSuccess = [];
            state.configiotSuccess = action.error.message;
        })
      .addCase(getIOTDevicesByTabletId.pending, (state) => {
        state.iotDevicesByTabletIdLoading = true;
      })
      .addCase(getIOTDevicesByTabletId.fulfilled, (state, action) => {
        state.iotDevicesByTabletIdLoading = false;
        const { result } = action.payload;
        state.iotDevicesByTabletId = result?.tablets || [];
      })
      .addCase(getIOTDevicesByTabletId.rejected, (state, action) => {
        state.iotDevicesByTabletIdLoading = false;
        state.iotDevicesByTabletId = [];
        state.iotDevicesByTabletId = action.error.message;
      })
        .addCase(getTabletDevicesByCompany.pending, (state) => {
            state.allTabletdeviceByCompanyLoading = true;
        })
        .addCase(getTabletDevicesByCompany.fulfilled, (state, action) => {
            state.allTabletdeviceByCompanyLoading = false;
            const { result } = action.payload;
            state.allTabletdeviceByCompany = result?.tablets || [];
        })
        .addCase(getTabletDevicesByCompany.rejected, (state, action) => {
            state.allTabletdeviceByCompanyLoading = false;
            state.allTabletdeviceByCompany = [];
            state.allTabletdeviceByCompany = action.error.message;
        });
  },
});

export default tabletDeviceSlice.reducer;
