import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { ToastSuccess, ToastError } from "../components/Toast";
import { axiosApiInstance } from '../@core/utils/axios';

const initialState = {
    allVehicleLocationLoading: false,
    allVehicleLocation: [],
    error: ''
};

export const getVehicleLocation = createAsyncThunk(
    'location/getVehicleLocation',
    async (pid) => {
        try {
            const { data } = await axiosApiInstance.get(`/GoogleMap/GetVehicleLocation?projectId=${pid}`);
            return data;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);


export const locationSlice = createSlice({
    name: 'location',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getVehicleLocation.pending, (state) => {
                state.allVehicleLocationLoading = true;
            })
            .addCase(getVehicleLocation.fulfilled, (state, action) => {
                state.allVehicleLocationLoading = false;
                const { result } = action.payload;
                state.allVehicleLocation = result;
            })
            .addCase(getVehicleLocation.rejected, (state, action) => {
                state.allVehicleLocationLoading = false;
                state.allVehicleLocation = [];
                state.allVehicleLocation = action.error.message;
            })
    },
});

export default locationSlice.reducer;
