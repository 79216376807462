import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { getCompanies } from "src/features/companySlice";
import { getRoles, createRole, updateRole } from "src/features/adminSlice";
import { ToastSuccess, ToastError } from 'src/components/Toast';

import {
  Select,
  FormControl,
  MenuItem,
  Button,
  Box,
  Grid,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";

const RoleForm = ({ role, handleClose }) => {
  const dispatch = useDispatch();
  const { allCompanies } = useSelector((state) => state.company);
  const { adminSelectedCompany } = useSelector((state) => state.company);
  const loginRes = JSON.parse(localStorage.getItem("loginRes"));
  const isSuperAdmin = loginRes?.superAdmin;

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors }
  } = useForm();

  useEffect(() => {
    dispatch(getCompanies());
  }, [dispatch]);

  useEffect(() => {
    reset(role || {});
  }, [role, reset]);

  const onSubmit = (data, e) => {
    if (!!role) {
      const updateData = { data: data, id: role.companyRoleSK };

      dispatch(updateRole(updateData)).then((res) => {
        console.log('res', res);
        if (res.payload?.data?.companyRoleSK) {
          ToastSuccess('Role saved');
          refreshRoles();
        }
      });
    } else {
      dispatch(createRole(data)).then((res) => {
        if (res.payload?.data?.companyRoleSK) {
          ToastSuccess('Role saved');
          refreshRoles();
        }
      });
    }

    handleClose();
  }

  const refreshRoles = () => {
    if(isSuperAdmin && adminSelectedCompany.companyId) {
      dispatch(getRoles({ companyId: adminSelectedCompany.companyId }));
    } else {
      dispatch(getRoles());
    }
  }

  const closeDialog = () => {
    handleClose();
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        {isSuperAdmin &&
          <Grid item xs={12}>
            <Controller
              name="companySK"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <FormControl fullWidth>
                  <InputLabel shrink>Company</InputLabel>
                  <Select
                    labelId="companySK"
                    id="companySK"
                    disabled={!!role}
                    {...register("companySK", { required: true })}
                    value={value || ""}
                    onChange={onChange}
                    input={<OutlinedInput notched label="Company" />}
                  >
                    <MenuItem value="">Select Value</MenuItem>
                    {allCompanies.map((company) => {
                      return (
                        <MenuItem value={company.companySK} key={company.companySK}>
                          {company.companyName}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
        }
        <Grid item xs={12}>
          <Controller
            name="roleName"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                error={!!error}
                helperText={error?.message}
                label="Name"
                value={field.value || ""}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button variant="outlined" onClick={closeDialog}>Cancel</Button>
            <Button variant="contained" type="submit">Save</Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  )
}

export default RoleForm;