import { Droppable, Draggable } from '@hello-pangea/dnd';
// MUI
import { Box } from '@mui/material';
import styled from '@mui/material/styles/styled';
// components
import { SystemStatusBadge } from '../ProjectComponents';
// assets
import { RollerIcon2 } from 'src/assets';

// ----------------------------------------------------------------------

export const CustomDraggablesContainer = styled(Box)(({ theme }) => ({
    background: '#f1f2f4',

    display: 'flex',
    alignItems: 'center',
    flex: '1 1 0%',

    padding: `0 ${dndGridSize}px`,
    overflow: 'hidden',
    minHeight: '52px',
    borderRadius: '3px',

    '&::-webkit-scrollbar': {
        width: '10px',
        height: '10px',
    },
    '&::-webkit-scrollbar-track': {
        background: '#e3e5ea',
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#c4c9d2',
        borderRadius: '10px',
        border: '3px solid #e3e5ea',
    }
}));

export const CustomDraggableItem = styled(Box)(({ theme }) => ({
    userSelect: 'none',
    padding: dndGridSize * 2,
    boxShadow: '0px 1px 1px #091e4240, 0px 0px 1px #091e424f',
    margin: `0 ${dndGridSize}px 0 0`,
    borderRadius: '3px',
    whiteSpace: 'nowrap',
    color: '#212529',

    minHeight: '40px',
    height: '40px',
    justifyContent: 'start',
    display: 'flex',
    alignItems: 'center',
    zIndex: 1,
    position: 'relative',

    // change background color if dragging
    background: "#ffffff",
    border: '2px solid #ffffff',

    '&:hover': {
        cursor: 'pointer',
        border: '2px solid #8bd0db',
    }
}));

// ----------------------------------------------------------------------

const EquipmentDroppable = ({ projectWithEquipment, droppableId, onEquipmentClick }) => {
    return (
        <Droppable droppableId={`${droppableId}`} direction="horizontal">
            {(provided, snapshot) => (
                <CustomDraggablesContainer
                    ref={provided.innerRef}
                    style={getHorizontalListStyle(snapshot.isDraggingOver)}
                    {...provided.droppableProps}
                >
                    {projectWithEquipment.equipment.length === 0 && (
                        <Box sx={dropMessageStyle}>Drop Equipment Here</Box>
                    )}

                    {projectWithEquipment.equipment.map((item, index) => (
                        <Draggable key={`${item.equipmentSK}`} draggableId={`${item.equipmentSK}`} index={index}>
                            {(provided, snapshot) => (
                                <CustomDraggableItem
                                    onClick={() => onEquipmentClick(item)}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getHorizontalItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                    )}
                                >
                                    <Box component="img" src={RollerIcon2} sx={{ height: 30, mr: 1 }} />
                                    {item.equipmentID}
                                    <SystemStatusBadge value={item.systemStatus}></SystemStatusBadge>
                                </CustomDraggableItem>
                            )}
                        </Draggable>
                    ))}
                    {provided.placeholder}
                </CustomDraggablesContainer>
            )}
        </Droppable>
    );
};

export default EquipmentDroppable;

const dndGridSize = 6;

const getHorizontalListStyle = isDraggingOver => ({
    background: isDraggingOver ? '#E4E6EA' : '#f1f2f4',
});
const getHorizontalItemStyle = (isDragging, draggableStyle) => ({
    // change background colour if dragging
    background: isDragging ? '#f1f1f1f0' : '#ffffff',
    borderColor: isDragging ? "#f1f1f1f0" : "",

    // styles we need to apply on draggables
    ...draggableStyle,
});

const dropMessageStyle = {
    color: '#cbcbcb',
    fontSize: '14px',
    position: 'absolute',
    zIndex: 0,
    marginLeft: '10px',
}