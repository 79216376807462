// MUI
import {
  Box,
  Dialog, DialogTitle, DialogContent
} from '@mui/material';
// components
import UserForm from './UserForm';

// ----------------------------------------------------------------------

export default function UserFormDialog({ open, onClose, user }) {
  const handleClose = () => {
    onClose(false);
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogTitle sx={{ mb: 1 }}>{!!user ? 'Update': 'Add'} User</DialogTitle>
      <DialogContent>
        <Box sx={{ pt: 1 }}>
          <UserForm user={user} onSaved={onClose} />
        </Box>
      </DialogContent>
    </Dialog>
  );
}
