import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { ToastSuccess, ToastError } from "../components/Toast";
import { axiosApiInstance } from '../@core/utils/axios';
import { parseRtdError } from '../@core/utils/parseRtdError';

const initialState = {
    usersLoading: false,
    userListLoading: false,
    userCreateLoading: false,
    userUpdateLoading: false,
    companyUserLoading: false,
    users: [],
    allUser: [],
    userById: [],
    companyUser: [],
    formSuccess: [],
    error: ''
};

export const getAllUsers = createAsyncThunk(
    'user/getAllUsers',
    async (loginIds, { dispatch }) => {
        try {
            const token = localStorage.getItem("token");
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
            const { data } = await axiosApiInstance.get(`/User/GetAllCompanyUser?companyId=${loginIds.companyId}&userId=${loginIds.userId}`, config);
            return data;
        } catch (error) {
            dispatch(handleUnauthorizedError(error));
            throw error;
        }
    }
);

export const handleUnauthorizedError = (error) => (dispatch) => {
    if (error.response && error.response.status === 401) {
        // Handle unauthorized error here (e.g., redirect to login page)
        // You can dispatch an action or perform any necessary logic
        ToastError("You are not authorized to open this page");
        console.log('Unauthorized error occurred');
    } else {
        // For other errors, you can dispatch an action to handle them
        // For example:
        // dispatch(someOtherAction(error.message));
    }
};

export const getCompanyUser = createAsyncThunk(
    'user/getCompanyUser',
    async (loginIds) => {
        try {
            const token = localStorage.getItem("token");
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
            const { data } = await axios.get(`${process.env.REACT_APP_APIURL}/User/GetCompanyUser?companyId=${loginIds.companyId}&userId=${loginIds.userId}`, config);
            return data;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);

export const postCustomerList = createAsyncThunk(
    'customer/postCustomerList',
    async (data) => {
        try {
            const token = localStorage.getItem("token");
            const loginId = JSON.parse(localStorage.getItem("loginRes"));
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
            const response = await axios.post(`${process.env.REACT_APP_APIURL}/Customer/Save?userId=${loginId.userId}`, data, config);
            return response;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);

export const updatePassword = createAsyncThunk(
    'customer/updatePassword',
    async (data) => {
        try {
            const token = localStorage.getItem("token");
            const loginId = JSON.parse(localStorage.getItem("loginRes"));
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
            const response = await axios.post(`${process.env.REACT_APP_APIURL}/User/UpdatePassword??userId=${loginId.userId}&companyId=${loginId.companyId}`, data, config);
            return response;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);

export const updateLostPassword = createAsyncThunk(
    'customer/updateLostPassword',
    async (data) => {
        try {
            const token = localStorage.getItem("token");
            const loginId = JSON.parse(localStorage.getItem("loginRes"));
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
            const response = await axios.post(`${process.env.REACT_APP_APIURL}/User/UpdateLostPassword??userId=${loginId.userId}&companyId=${loginId.companyId}`, data, config);
            return response;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);

// V2
export const getUsers = createAsyncThunk(
    'user/getUsers',
    async (data = { companyId: null }) => {
        try {
            let url = `/api/v2/users?`;

            if (data.companyId) {
                url += `companyId=${data.companyId}&`;
            }

            url = url.slice(0, -1); // Remove the trailing '&' or '?'

            const response = await axiosApiInstance.get(url);
            return response.data;
        } catch (error) {
            ToastError(error.message);
        }
    }
);

export const createUser = createAsyncThunk(
    'user/createUser',
    async (data) => {
        try {
            return await axiosApiInstance.post(`/api/v2/users`, data);
        } catch (error) {
            const message = parseRtdError(error);
            ToastError(message);
        }
    }
);

export const updateUser = createAsyncThunk(
    'user/updateUser',
    async (updateData) => {
        try {
            const { id, data } = updateData;
            const url = `/api/v2/users/${id}`;
            return await axiosApiInstance.put(url, data);
        } catch (error) {
            const message = parseRtdError(error);
            ToastError(message);
        }
    }
);

export const updatePasswordV2 = createAsyncThunk(
    'user/updatePasswordV2',
    async (updateData) => {
        try {
            const { id, data } = updateData;
            const url = `/api/v2/users/${id}/password`;
            return await axiosApiInstance.put(url, data);
        } catch (error) {
            const message = parseRtdError(error);
            ToastError(message);
        }
    }
);

export const userSlice = createSlice({
    name: 'user',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getUsers.pending, (state) => {
                state.usersLoading = true;
            })
            .addCase(getUsers.fulfilled, (state, action) => {
                state.usersLoading = false;
                state.users = action.payload || [];
            })
            .addCase(getUsers.rejected, (state, action) => {
                state.usersLoading = false;
                state.users = [];
            })

            .addCase(createUser.pending, (state) => {
                state.userCreateLoading = true;
            })
            .addCase(createUser.fulfilled, (state, action) => {
                state.userCreateLoading = false;
                state.formSuccess = action.payload?.data;
            })
            .addCase(createUser.rejected, (state, action) => {
                state.userCreateLoading = false;
                state.formSuccess = {};
                state.error = action.error.message;
            })

            .addCase(updateUser.pending, (state) => {
                state.userUpdateLoading = true;
            })
            .addCase(updateUser.fulfilled, (state, action) => {
                state.userUpdateLoading = false;
                state.formSuccess = action.payload?.data;
            })
            .addCase(updateUser.rejected, (state, action) => {
                state.userUpdateLoading = false;
                state.formSuccess = {};
                state.error = action.error.message;
            })

            .addCase(updatePasswordV2.pending, (state) => {
                state.userUpdateLoading = true;
            })
            .addCase(updatePasswordV2.fulfilled, (state, action) => {
                state.userUpdateLoading = false;
                state.formSuccess = action.payload?.data;
            })
            .addCase(updatePasswordV2.rejected, (state, action) => {
                state.userUpdateLoading = false;
                state.formSuccess = {};
                state.error = action.error.message;
            })

            // V1
            .addCase(getAllUsers.pending, (state) => {
                state.userListLoading = true;
            })
            .addCase(getAllUsers.fulfilled, (state, action) => {
                state.userListLoading = false;
                const { result } = action.payload;
                state.allUser = result?.users || [];
            })
            .addCase(getAllUsers.rejected, (state, action) => {
                state.userListLoading = false;
                state.allUser = [];
                state.allUser = action.error.message;
            })
            .addCase(getCompanyUser.pending, (state) => {
                state.companyUserLoading = true;
            })
            .addCase(getCompanyUser.fulfilled, (state, action) => {
                state.companyUserLoading = false;
                const { result } = action.payload;
                state.companyUser = result?.users || [];
            })
            .addCase(getCompanyUser.rejected, (state, action) => {
                state.companyUserLoading = false;
                state.companyUser = [];
                state.companyUser = action.error.message;
            });
    },
});

export default userSlice.reducer;
