import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { axiosApiInstance } from "src/@core/utils/axios";
// redux
import { useDispatch, useSelector } from "react-redux";
import { getCompanies } from "src/features/companySlice";
// assets
import { iconSet } from "src/@core/data/icons";
// config
import { permission } from "src/@core/data/permission";
// MUI
import {
    Box, Card, CardContent, Button
} from "@mui/material";
// components
import Table from "src/components/table/Table";
import { CircleLoading } from "src/components/loadingCircle";
import ActionMenu from "src/components/actionmenu";
import { ToastSuccess, ToastError } from "../../components/Toast";
import PageBreadcrumbs from "src/components/PageBreadcrumbs";
import ConfirmDialog from "src/components/ConfirmDialog";

// ----------------------------------------------------------------------

//let adminOptions = ["Edit", "Delete"]; // removing delete option until we have soft deletes
let adminOptions = ["Edit"];

const breadcrumbs = [
    { route: "/home", label: "Home" },
    { route: "", label: "Companies" },
];

// ----------------------------------------------------------------------

export default function CompanyPage() {
    const navigate = useNavigate();
    const loginRes = JSON.parse(localStorage.getItem("loginRes"));
    const isSuperAdmin = loginRes?.superAdmin;
    const dispatch = useDispatch();
    const { loading, allCompanies } = useSelector((state) => state.company);
    const { roles } = useSelector((state) => state.login);
    const [deleteConfirm, setDeleteConfirm] = useState({ open: false, companyId: null });

    let hideColumn = ['companySK'];
    const canEditCompany = roles.userCompanyRolePermissionInfo.some(
        (rolscheck) =>
            rolscheck.permissionName === permission.company && rolscheck.isWrite
    ) || isSuperAdmin;

    let COLUMNS = [
        {
            Header: "Name",
            accessor: "companyName",
            Cell: ({ row }) => (
                <div>
                    {canEditCompany ? (
                        <Link
                            style={{ textAlign: "left" }}
                            to={`/companies/form?rid=${row.values.companySK}`}
                        >
                            {row.values.companyName}
                        </Link>
                    ) : row.values.companyName}
                </div>
            ),
        },
        {
            Header: "Company SK",
            accessor: "companySK",
            show: false,
        },
        {
            Header: "Company Id",
            accessor: "companyId",
        },
        {
            Header: "Phone",
            accessor: "phone",
        },
        {
            Header: "Action",
            Cell: ({ row }) => (
                <div>
                    {canEditCompany && (
                        <ActionMenu
                            key="action-menu"
                            options={adminOptions}
                            handleMenuClick={(option) =>
                                handleItemMenuClick(row.values.companySK, option)
                            }
                        />
                    )}
                </div>
            ),
        },
    ];

    const handleItemMenuClick = (companySK, option) => {
        if (option === "Edit") {
            onEditCompany(companySK);
        } else if (option === "Delete") {
            setDeleteConfirm({ open: true, companyId: companySK });
        }
    };

    const closeDeleteConfirm = (confirm) => {
        if (confirm) {
            deleteCompany(deleteConfirm.companyId);
        } else {
            setDeleteConfirm({ open: false, companyId: null });
        }
    }

    const deleteCompany = async (companySK) => {
        const url = `/api/v2/companies/${companySK}`;

        try {
            await axiosApiInstance.delete(url);
            setDeleteConfirm({ open: false, companyId: null });
            dispatch(getCompanies());
            ToastSuccess('Company deleted!');
        } catch (error) {
            ToastError('An error occurred');
        }
    };

    const onCreateClick = () => {
        navigate("/companies/form");
    };

    const onEditCompany = (rowId) => {
        navigate(`/companies/form?rid=${rowId}`);
    };

    useEffect(() => {
        dispatch(getCompanies());
    }, [dispatch]);

    return (
        <Box>
            <Box className="d-flex flex-column px-md-4 form-layout customer-form">
                <Box className="d-flex mb-2 mt-2">
                    <Box className="flex-grow-1">
                        <PageBreadcrumbs pageName="Companies" breadcrumbs={breadcrumbs} icon={iconSet.company} />
                    </Box>
                    <Box className="heading-right-btn">
                        {isSuperAdmin && (
                            <Button variant="contained" onClick={onCreateClick}>
                                Create Company
                            </Button>
                        )}
                    </Box>
                </Box>
                <Card>
                    <CardContent>
                        {loading && (<CircleLoading />)}

                        {!loading && (
                            <Table
                                COLUMNS={COLUMNS}
                                parseData={allCompanies}
                                hideColumn={hideColumn}
                                heading={"Companies"}
                            />
                        )}
                    </CardContent>
                </Card>
            </Box>

            <ConfirmDialog
                open={deleteConfirm.open}
                title="Delete Company"
                message="Are you sure you want to delete this Company? This cannot be undone."
                onClose={closeDeleteConfirm}
                maxWidth="xs"
            />
        </Box>
    );
};

