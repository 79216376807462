
// google icon font

export const iconSet =
{
    dashboard: "store",
    customer: "supervisor_account",
    company: "corporate_fare",
    switchcompany: "lan",
    users: "person",
    project: "folder_open",
    equipment: "settings_input_component",
    system: "developer_board",
    tablet: "audio_video_receiver",
    iot: "hub",
    roles: "how_to_reg",
    permission: "shield_lock",
    rolespermissionmap: "event_note",
    location:"map",
    dataKey: "key_vertical",
}
