export function logout() {
  navigate("/");
}

export const userName = () => {
  return localStorage.getItem("userName");
}

/**
 * Helper function to check if the token is expired
 * 
 * @returns {boolean} - True if the token is expired, false otherwise
 */
export const isJwtExpired = (token) => {
  if (!token) return true;

  const payload = JSON.parse(atob(token.split('.')[1]));
  const expiration = payload.exp * 1000; // Convert expiration to milliseconds

  return Date.now() >= expiration;
};