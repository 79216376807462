import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
// MUI
import {
    Select, FormControl, MenuItem, Box, InputLabel,
    Grid, TextField, OutlinedInput,
    TextareaAutosize, Divider,
} from "@mui/material";
// redux
import { getCompanies } from "src/features/companySlice";
import { getEquipmentByIdV2, createEquipment, updateEquipment } from "src/features/equipmentSlice";
import { ToastSuccess, ToastError } from "src/components/Toast";
import LoadingButton from 'src/components/LoadingButton';

// ----------------------------------------------------------------------

const defaultFormValues = {
    companySK: "",
    equipmentID: "",
    manufacturer: "",
    model: "",
    drumSize: "",
    description: "",
};

// ----------------------------------------------------------------------

export default function EquipmentForm() {
    const loginRes = JSON.parse(localStorage.getItem("loginRes"));
    const isSuperAdmin = loginRes?.superAdmin;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [submitting, setSubmitting] = useState(false);
    const { allCompanies } = useSelector((state) => state.company);
    const { equipmentById } = useSelector((state) => state.equipment);

    // react hook form
    const {
        register,
        handleSubmit,
        reset,
        control,
        formState: { errors },
        setValue
    } = useForm({
        defaultValues: defaultFormValues
    });

    useEffect(() => {
        if (equipmentById) {
            const formData = {
                companySK: equipmentById.companySK || "",
                equipmentID: equipmentById.equipmentID || "",
                manufacturer: equipmentById.manufacturer || "",
                model: equipmentById.model || "",
                drumSize: equipmentById.drumSize || "",
                description: equipmentById.description || "",
            };

            reset(formData);
        } else {
            reset();
        }
    }, [equipmentById]);

    useEffect(() => {
        dispatch(getCompanies());
    }, [dispatch]);

    const onSubmit = (data) => {
        setSubmitting(true);

        if (equipmentById?.equipmentSK) {
            const updateData = {
                id: equipmentById.equipmentSK,
                data: {
                    ...data,
                    companySK: data.companySK || null
                }
            };

            dispatch(updateEquipment(updateData)).then((res) => {
                ToastSuccess("Equipment saved");
                setSubmitting(false);
            });
        } else {
            const createData = {
                ...data,
                companySK: data.companySK || null
            };

            dispatch(createEquipment(createData)).then((res) => {
                setSubmitting(false);
                ToastSuccess("Equipment saved");

                const url = `/equipment/form?id=${res.payload.data.equipmentSK}`;
                navigate(url, { replace: true });
            });
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
                {isSuperAdmin && (
                    <Grid item xs={12} sm={12} md={4}>
                        <Controller
                            name="companySK"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value } }) => (
                                <FormControl fullWidth>
                                    <InputLabel shrink>Company</InputLabel>
                                    <Select
                                        labelId="companySK-label"
                                        id="companySK"
                                        value={value}
                                        {...register("companySK", { required: false })}
                                        onChange={(e) => {
                                            onChange(e);
                                        }}
                                        input={<OutlinedInput notched label="Company" />}
                                    >
                                        <MenuItem value="">Select Value</MenuItem>
                                        {allCompanies.map((company) => (
                                            <MenuItem value={company.companySK} key={company.companySK}>
                                                {company.companyName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}
                        />
                    </Grid>
                )}

                {isSuperAdmin && (
                    <Grid item xs={12} sx={{ paddingTop: '0px' }}>
                        <Divider sx={{ my: 2 }} />
                    </Grid>
                )}

                <Grid item xs={12} sm={6} md={4}>
                    <Controller
                        name="equipmentID"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <TextField {...field} fullWidth error={!!error} helperText={error?.message} label="Equipment ID" />
                        )}

                    />
                </Grid>
                <Grid item xs={12} sm={12} md={4} />
                <Grid item xs={12} sm={12} md={4} />

                <Grid item xs={12} sm={6} md={4}>
                    <Controller
                        name="manufacturer"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <TextField {...field} fullWidth error={!!error} helperText={error?.message} label="Make" />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Controller
                        name="model"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <TextField {...field} fullWidth error={!!error} helperText={error?.message} label="Model" />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Controller
                        name="drumSize"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <TextField {...field} fullWidth error={!!error} helperText={error?.message} label="Drum Size" />
                        )}
                    />
                </Grid>

                <Grid item xs={12} sm={12} md={8}>
                    <Controller
                        name="description"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                {...field}
                                fullWidth
                                error={!!error}
                                helperText={error?.message}
                                label="Description"
                                multiline
                                rows={4}
                                InputProps={{
                                    inputComponent: TextareaAutosize,
                                }}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <LoadingButton
                            type="submit"
                            variant="contained"
                            color="primary"
                            loading={submitting}
                            sx={{ width: '110px' }}
                        >
                            Save
                        </LoadingButton>
                    </Box>
                </Grid>
            </Grid>
        </form>
    );
};

