import { useEffect } from "react";
// form
import { useForm, Controller } from "react-hook-form";
// redux
import { useDispatch, useSelector } from "react-redux";
import { getCompanies } from "src/features/companySlice";
import { getPermissions, createPermission, updatePermission } from "src/features/adminSlice";
// types
import { PermissionOptions } from "src/@core/data/permission";
// MUI
import {
    Select,
    Grid,
    FormControl,
    MenuItem,
    Box,
    Button,
    InputLabel,
    OutlinedInput,
    TextareaAutosize,
    TextField,
} from "@mui/material";
// components
import { ToastSuccess } from 'src/components/Toast';

// ----------------------------------------------------------------------

const PermissionForm = ({ permission, handleClose }) => {
    const dispatch = useDispatch();
    const loginRes = JSON.parse(localStorage.getItem("loginRes"));
    const isSuperAdmin = loginRes?.superAdmin;
    const { allCompanies } = useSelector((state) => state.company);
    const { adminSelectedCompany } = useSelector((state) => state.company);

    const {
        register,
        handleSubmit,
        reset,
        control,
        formState: { errors }
    } = useForm();

    useEffect(() => {
        dispatch(getCompanies());
    }, [dispatch]);

    useEffect(() => {
        !!permission ? reset(permission) : reset();
    }, [permission]);

    const onSubmit = (data, e) => {
        if(!!permission){
            const updateData = { data: data, id: permission.permissionSK };

            dispatch(updatePermission(updateData)).then((res) => {
                if (res.payload?.data?.permissionSK) {
                    ToastSuccess('Permission saved');
                    refreshPermissions();
                }
            });
        } else {
            dispatch(createPermission(data)).then((res) => {
                if (res.payload?.data?.permissionSK)  {
                    ToastSuccess('Permission saved');
                    refreshPermissions();
                }
            });
        }
        handleClose();
    }

    const refreshPermissions = () => {
        if(isSuperAdmin && adminSelectedCompany.companyId) {
            dispatch(getPermissions({ companyId: adminSelectedCompany.companyId }));
        } else {
            dispatch(getPermissions());
        }
    }

    const closeDialog = () => {
        handleClose();
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
                {isSuperAdmin &&
                    <Grid item xs={12}>
                        <Controller
                            name="companySK"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value } }) => (
                                <FormControl fullWidth>
                                    <InputLabel shrink>Company</InputLabel>
                                    <Select
                                        labelId="companySK"
                                        id="companySK"
                                        disabled={!!permission}
                                        {...register("companySK", { required: true })}
                                        value={value}
                                        onChange={onChange}
                                        input={<OutlinedInput notched label="Company" />}
                                    >
                                        <MenuItem value="">Select Value</MenuItem>
                                        {allCompanies.map((company) => {
                                            return (
                                                <MenuItem value={company.companySK} key={company.companySK}>
                                                    {company.companyName}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            )}
                        />
                    </Grid>
                }
                <Grid item xs={12}>
                    <Controller
                        name="name"
                        control={control}
                        defaultValue=""
                        render={({ field: { onChange, value } }) => (
                            <FormControl fullWidth>
                                <InputLabel shrink>Permission</InputLabel>
                                <Select
                                    labelId="name"
                                    id="name"
                                    value={value}
                                    {...register("name", { required: true })}
                                    onChange={onChange}
                                    input={<OutlinedInput notched label="Permission" />}
                                >
                                    {Object.values(PermissionOptions).map((value) => {
                                        return (
                                            <MenuItem value={value} key={value}>{value}</MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        name="description"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                {...field}
                                fullWidth
                                error={!!error}
                                helperText={error?.message}
                                label="Description"
                                multiline
                                rows={4}
                                InputProps={{
                                    inputComponent: TextareaAutosize,
                                }}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button variant="outlined" onClick={closeDialog}>Cancel</Button>
                        <Button
                            variant="contained"
                            type="submit"
                        >
                            Save
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </form >
    )
}

export default PermissionForm;