import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { ToastSuccess, ToastError } from "../components/Toast";
import { axiosApiInstance } from '../@core/utils/axios';

const initialState = {
    categoryListLoading: false,
    categoryRowLoading: false,
    allCategory: [],
    categoryById: [],
    formSuccess: [],
    error: ''
};

export const getAllCategory = createAsyncThunk(
    'category/getAllCategory',
    async () => {
        try {
            const { data } = await axiosApiInstance.get(`/Device/GetAllCategory`);
            return data;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);

export const getDeviceCategoryById = createAsyncThunk(
    'category/getDeviceCategoryById',
    async (loginIds) => {
        try {
            const { data } = await axiosApiInstance.get(`/Device/GetCategoryById?categorySK=${loginIds.rowId}`);
            return data;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);

export const postCategory = createAsyncThunk(
    'category/postCategory',
    async (data) => {
        try {
            const loginId = JSON.parse(localStorage.getItem("loginRes"));
            const response = await axiosApiInstance.post(`/Device/AddCategory?userId=${loginId.userId}`, data);
            return response;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);


export const categorySlice = createSlice({
    name: 'category',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getAllCategory.pending, (state) => {
                state.categoryListLoading = true;
            })
            .addCase(getAllCategory.fulfilled, (state, action) => {
                state.categoryListLoading = false;
                const { result } = action.payload;
                state.allCategory = result?.category || [];
            })
            .addCase(getAllCategory.rejected, (state, action) => {
                state.categoryListLoading = false;
                state.allCategory = [];
                state.allCategory = action.error.message;
            })
            .addCase(getDeviceCategoryById.pending, (state) => {
                state.categoryRowLoading = true;
            })
            .addCase(getDeviceCategoryById.fulfilled, (state, action) => {
                state.categoryRowLoading = false;
                const { result } = action.payload;
                state.categoryById = result?.category || [];
            })
            .addCase(getDeviceCategoryById.rejected, (state, action) => {
                state.categoryRowLoading = false;
                state.categoryById = [];
                state.categoryById = action.error.message;
            })
            .addCase(postCategory.fulfilled, (state, action) => {
                state.formSuccess = action.payload;
            })
            .addCase(postCategory.rejected, (state, action) => {
                state.formSuccess = [];
                state.formSuccess = action.error.message;
            })
    },
});

export default categorySlice.reducer;