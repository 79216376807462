import { useState, useEffect, useRef } from "react";
// redux
import { useDispatch, useSelector } from "react-redux";
import { getUnassignedDataKeys } from "src/features/dataKeySlice";
// MUI
import { Box, Typography, CircularProgress } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import styled from '@mui/material/styles/styled';
// components
import DataKeyFormDialog from "src/pages/data-key/components/DataKeyFormDialog";
import DataKeyCard from "./DataKeyCard";
import { RtdCardTitle } from "src/components/RtdCard";

// ----------------------------------------------------------------------

const AccordionHeader = styled(Box)(({ theme }) => ({
    cursor: 'pointer',
    userSelect: 'none',
    padding: '8px',
    borderRadius: '3px 3px 0 0',
    boxShadow: 'rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.31) 0px 0px 1px',
    color: 'rgb(33, 37, 41)',

    background: 'rgb(255, 255, 255)',
    '&:hover': {
        background: 'rgb(250 250 250 )',
    },

    height: '49px',
    display: 'flex',
    alignItems: 'center',

    flex: '0 1 auto',
    lineHeight: '0',
}));

const AccordionInnerHeader = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
}));

// ----------------------------------------------------------------------

const UnassignedDataKeys = () => {
    const loginRes = JSON.parse(localStorage.getItem("loginRes"));
    const isSuperAdmin = loginRes?.superAdmin;
    const dispatch = useDispatch();
    const contentRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const [contentHeight, setContentHeight] = useState(100);
    const [curAccordionStyle, setCurAccordionStyle] = useState(getAccordionStyle(0));
    const [dataKeyDialog, setDataKeyDialog] = useState({ open: false, dataKey: null });
    const { loadingUnassigned, unassignedDataKeys } = useSelector((state) => state.dataKey);
    const { adminSelectedCompany } = useSelector((state) => state.company);

    useEffect(() => {
        // Initial query to update the count in the accordion header
        refreshUnassignedDataKeys();
    }, [dispatch, adminSelectedCompany]);

    useEffect(() => {
        // Refresh the data keys on open
        if (isOpen) {
            refreshUnassignedDataKeys();
        }
    }, [dispatch, isOpen]);

    const refreshUnassignedDataKeys = () => {
        if (isSuperAdmin && adminSelectedCompany?.companyId) {
            const data = { unassigned: true, companyId: adminSelectedCompany.companyId };
            dispatch(getUnassignedDataKeys(data));
        } else {
            const data = { unassigned: true };
            dispatch(getUnassignedDataKeys(data));
        }
    }

    const toggleAccordion = () => {
        if (isOpen) {
            setCurAccordionStyle(getAccordionStyle(0));
        }

        setIsOpen(!isOpen);
    }

    useEffect(() => {
        const updateHeight = () => {
            if (contentRef.current) {
                setContentHeight(contentRef.current.scrollHeight);
            }
        };

        updateHeight();

        window.addEventListener('resize', updateHeight);

        return () => {
            window.removeEventListener('resize', updateHeight);
        };
    }, [isOpen, loadingUnassigned]);

    useEffect(() => {
        const newHeight = isOpen ? contentHeight : 0;

        setCurAccordionStyle(getAccordionStyle(newHeight));
    }, [isOpen, loadingUnassigned, contentHeight]);

    const onEditDataKey = (dataKey) => {
        setDataKeyDialog({ open: true, dataKey: dataKey });
    };

    const closeDataKeyDialog = () => {
        setDataKeyDialog({ open: false, dataKey: null });
        refreshUnassignedDataKeys();
    }

    return (
        <Box sx={{ mb: 2 }}>
            <Box>
                <AccordionHeader onClick={toggleAccordion}>
                    <AccordionInnerHeader>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box>
                                <RtdCardTitle>
                                    Unassigned Data Keys <Typography component="span" sx={{ color: 'grey.600' }}>({unassignedDataKeys?.length ? unassignedDataKeys.length : '0'})</Typography>
                                </RtdCardTitle>
                                <Typography variant="caption" sx={{ color: 'grey.600' }}>
                                    Assign data keys to a project
                                </Typography>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <ExpandMore sx={getExpandIconStyle(isOpen)} />
                        </Box>
                    </AccordionInnerHeader>
                </AccordionHeader>
            </Box>
            <Box ref={contentRef} sx={curAccordionStyle}>
                <Box sx={{ p: 1, display: 'flex', flexWrap: 'wrap' }}>
                    {loadingUnassigned && (
                        <Box sx={{ width: '100%', textAlign: 'center' }}>
                            <CircularProgress />
                        </Box>
                    )}

                    {!loadingUnassigned && unassignedDataKeys?.map((dataKey, index) => (
                        <DataKeyCard key={index} dataKey={dataKey} onEdit={onEditDataKey} disableMapButton={true} />
                    ))}

                    {!loadingUnassigned && unassignedDataKeys?.length < 1 && (
                        <Box sx={{ width: '100%', textAlign: 'center', color: '#979797' }}>
                            No unassigned data keys found
                        </Box>
                    )}
                </Box>
            </Box>

            <DataKeyFormDialog
                open={dataKeyDialog.open}
                onClose={closeDataKeyDialog}
                dataKey={dataKeyDialog.dataKey}
            />
        </Box>
    );
};

export default UnassignedDataKeys;

const getAccordionStyle = (contentHeight) => ({
    maxHeight: `${contentHeight}px`,
    overflow: 'hidden',
    transition: 'max-height 0.25s ease-in-out',
    whiteSpace: 'normal',
    borderRadius: '0 0 3px 3px ',
    boxShadow: 'rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.31) 0px 0px 1px',
    color: 'rgb(33, 37, 41)',
    backgroundColor: '#f1f2f4',
});

const getExpandIconStyle = (isOpen) => ({
    transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
    transition: 'transform 0.3s ease-in-out'
});
