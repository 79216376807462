// MUI
import {
  Dialog, DialogTitle, DialogContent, DialogActions, Button, Box
} from '@mui/material';

// ----------------------------------------------------------------------

export default function ConfirmDialog({ open, title, message, onClose, maxWidth }) {
  return (
    <Dialog
      open={open}
      onClose={() => onClose(false)}
      fullWidth={true}
      maxWidth={maxWidth || 'sm' }
    >
      <DialogTitle sx={{ mb: 1 }}>{title}</DialogTitle>
      <DialogContent>
        {message}
      </DialogContent>
      <DialogActions sx={{ paddingLeft: '1.4em' }}>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
          <Button variant="outlined" onClick={() => onClose(false)} color="primary">
            No
          </Button>
          <Button variant="contained" onClick={() => onClose(true)} color="primary">
            Yes
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
