import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { ToastSuccess, ToastError } from "../components/Toast";
import { axiosApiInstance } from '../@core/utils/axios';

const initialState = {
    modelListLoading: false,
    modelRowLoading: false,
    allModel: [],
    modelById: [],
    formSuccess: [],
    error: ''
};

export const getAllDeviceModel = createAsyncThunk(
    'model/getAllDeviceModel',
    async () => {
        try {
            const { data } = await axiosApiInstance.get(`/Device/GetAllModel?modelType=A`);
            return data;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);

export const getDeviceModelById = createAsyncThunk(
    'model/getDeviceModelById',
    async (loginIds) => {
        try {
            const { data } = await axiosApiInstance.get(`/Device/GetModelById?modelSK=${loginIds.rowId}`);
            return data;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);

export const postModel = createAsyncThunk(
    'model/postModel',
    async (data) => {
        try {
            const loginId = JSON.parse(localStorage.getItem("loginRes"));
            const response = await axiosApiInstance.post(`/Device/AddModel?userId=${loginId.userId}`, data);
            return response;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);


export const modelSlice = createSlice({
    name: 'model',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getAllDeviceModel.pending, (state) => {
                state.modelListLoading = true;
            })
            .addCase(getAllDeviceModel.fulfilled, (state, action) => {
                state.modelListLoading = false;
                const { result } = action.payload;
                state.allModel = result?.models || [];
            })
            .addCase(getAllDeviceModel.rejected, (state, action) => {
                state.modelListLoading = false;
                state.allModel = [];
                state.allModel = action.error.message;
            })
            .addCase(getDeviceModelById.pending, (state) => {
                state.modelRowLoading = true;
            })
            .addCase(getDeviceModelById.fulfilled, (state, action) => {
                state.modelRowLoading = false;
                const { result } = action.payload;
                state.modelById = result?.model || [];
            })
            .addCase(getDeviceModelById.rejected, (state, action) => {
                state.modelRowLoading = false;
                state.modelById = [];
                state.modelById = action.error.message;
            })
            .addCase(postModel.fulfilled, (state, action) => {
                state.formSuccess = action.payload;
            })
            .addCase(postModel.rejected, (state, action) => {
                state.formSuccess = [];
                state.formSuccess = action.error.message;
            })
    },
});

export default modelSlice.reducer;