import * as turf from "@turf/turf";

export class MapManager {
    /**
     * The highlight layer must be created after the hexbin layers.
     * 
     * @param {*} map 
     * @param {*} highlightHexbinLayer 
     */
    static initHighlightHexbinLayer(map, highlightHexbinLayer) {
        const highlightSourceName = highlightHexbinLayer.source_name;
        const highlightLayerName = highlightHexbinLayer.layer_name;
        const defaultFeatures = turf.featureCollection([]);

        // init layer
        if (!map.current.getSource(highlightSourceName)) {
            // source
            map.current.addSource(highlightSourceName, {
                type: 'geojson',
                data: defaultFeatures
            });

            // layer for the hexgrid outlines
            map.current.addLayer({
                id: highlightLayerName,
                type: 'line',
                source: highlightSourceName,
                layout: {},
                paint: {
                    'line-color': '#FFFF00',
                    'line-width': 2,
                    'line-opacity': 1,
                }
            });
        }
    }

    static updateHighlightHexbinLayer(map, highlightHexbinLayer, hexbinInfo) {
        const sourceName = highlightHexbinLayer.source_name;
        const layerName = highlightHexbinLayer.layer_name;

        const showLayer = !!hexbinInfo;

        if (map.current.getSource(sourceName)) {
            if (showLayer) {
                map.current.setLayoutProperty(layerName, 'visibility', 'visible');
                map.current.getSource(sourceName).setData(hexbinInfo);
            } else {
                map.current.setLayoutProperty(layerName, 'visibility', 'none');
            }
        }
    }

    static initDensityHexbinLayer(map, densityLayer, onHexbinClick) {
        const hexGridSourceName = densityLayer.layer_info.source_name;
        const hexGridLayerName = densityLayer.layer_info.layer_name;
        const hexGridOutlineLayerName = densityLayer.layer_info.outline_layer_name;
        const colorRange = densityLayer.layer_info.color_range;

        const defaultFeatures = turf.featureCollection([]);

        map.current.addSource(hexGridSourceName, {
            type: 'geojson',
            data: defaultFeatures
        });

        // hexgrid layer
        map.current.addLayer({
            id: hexGridLayerName,
            source: hexGridSourceName,
            type: 'fill',
            layout: {},
            paint: {
                'fill-color': [
                    'interpolate',
                    ['linear'],
                    ['get', 'density'],
                    ...colorRange
                ],
                'fill-opacity': 0.9
            }
        });

        // layer for the hexgrid outlines
        map.current.addLayer({
            id: hexGridOutlineLayerName,
            type: 'line',
            source: hexGridSourceName,
            layout: {},
            paint: {
                'line-color': '#000',
                'line-width': 1,
                'line-opacity': 0.3
            }
        });

        map.current.on('click', hexGridLayerName, (e) => {
            if (e.features.length > 0) {
                const feature = e.features[0];
                onHexbinClick(feature);
            }
        });
    }

    static updateDensityHexbinLayer(map, densityLayer, showLayer, hexGridGeoJson) {
        const hexGridSourceName = densityLayer.layer_info.source_name;
        const hexGridLayerName = densityLayer.layer_info.layer_name;
        const hexGridOutlineLayerName = densityLayer.layer_info.outline_layer_name;

        if (map.current.getSource(hexGridSourceName)) {
            if (showLayer) {
                map.current.getSource(hexGridSourceName).setData(hexGridGeoJson);
                map.current.setLayoutProperty(hexGridLayerName, 'visibility', 'visible');
                map.current.setLayoutProperty(hexGridOutlineLayerName, 'visibility', 'visible');
            } else {
                map.current.setLayoutProperty(hexGridLayerName, 'visibility', 'none');
                map.current.setLayoutProperty(hexGridOutlineLayerName, 'visibility', 'none');
            }

            const colorRange = densityLayer.layer_info.color_range;

            map.current.setPaintProperty(hexGridLayerName,
                'fill-color', [
                'interpolate',
                ['linear'],
                ['get', 'density'],
                ...colorRange
            ]
            );
        }
    }

    static initPassCountHexbinLayer(map, passCountLayer, onHexbinClick) {
        const hexGridSourceName = passCountLayer.layer_info.source_name;
        const hexGridLayerName = passCountLayer.layer_info.layer_name;
        const hexGridOutlineLayerName = passCountLayer.layer_info.outline_layer_name;
        const colorRange = passCountLayer.layer_info.color_range;

        const defaultFeatures = turf.featureCollection([]);

        map.current.addSource(hexGridSourceName, {
            type: 'geojson',
            data: defaultFeatures
        });

        // hexgrid layer
        map.current.addLayer({
            id: hexGridLayerName,
            source: hexGridSourceName,
            type: 'fill',
            layout: {},
            paint: {
                'fill-color': [
                    'interpolate',
                    ['linear'],
                    ['get', 'pass_count'],
                    ...colorRange
                ],
                'fill-opacity': 0.9
            }
        });

        // layer for the hexgrid outlines
        map.current.addLayer({
            id: hexGridOutlineLayerName,
            type: 'line',
            source: hexGridSourceName,
            layout: {},
            paint: {
                'line-color': '#000',
                'line-width': 1,
                'line-opacity': 0.3
            }
        });

        map.current.on('click', hexGridLayerName, (e) => {
            if (e.features.length > 0) {
                const feature = e.features[0];
                onHexbinClick(feature);
            }
        });
    }

    static updatePassCountHexbinLayer(map, passCountLayer, showLayer, hexGridGeoJson) {
        const hexGridSourceName = passCountLayer.layer_info.source_name;
        const hexGridLayerName = passCountLayer.layer_info.layer_name;
        const hexGridOutlineLayerName = passCountLayer.layer_info.outline_layer_name;

        if (map.current.getSource(hexGridSourceName)) {
            if (showLayer) {
                map.current.getSource(hexGridSourceName).setData(hexGridGeoJson);
                map.current.setLayoutProperty(hexGridLayerName, 'visibility', 'visible');
                map.current.setLayoutProperty(hexGridOutlineLayerName, 'visibility', 'visible');
            } else {
                map.current.setLayoutProperty(hexGridLayerName, 'visibility', 'none');
                map.current.setLayoutProperty(hexGridOutlineLayerName, 'visibility', 'none');
            }

            const colorRange = passCountLayer.layer_info.color_range;

            map.current.setPaintProperty(hexGridLayerName,
                'fill-color', [
                'interpolate',
                ['linear'],
                ['get', 'pass_count'],
                ...colorRange
            ]
            );
        }
    }

    static initTemperatureHexbinLayer(map, temperatureLayer, onHexbinClick) {
        const hexGridSourceName = temperatureLayer.layer_info.source_name;
        const hexGridLayerName = temperatureLayer.layer_info.layer_name;
        const hexGridOutlineLayerName = temperatureLayer.layer_info.outline_layer_name;
        const colorRange = temperatureLayer.layer_info.color_range;

        const defaultFeatures = turf.featureCollection([]);

        map.current.addSource(hexGridSourceName, {
            type: 'geojson',
            data: defaultFeatures
        });

        // hexgrid layer
        map.current.addLayer({
            id: hexGridLayerName,
            source: hexGridSourceName,
            type: 'fill',
            layout: {},
            paint: {
                'fill-color': [
                    'interpolate',
                    ['linear'],
                    ['get', 'temperature'],
                    ...colorRange
                ],
                'fill-opacity': 0.9
            }
        });

        // layer for the hexgrid outlines
        map.current.addLayer({
            id: hexGridOutlineLayerName,
            type: 'line',
            source: hexGridSourceName,
            layout: {},
            paint: {
                'line-color': '#000',
                'line-width': 1,
                'line-opacity': 0.3
            }
        });

        map.current.on('click', hexGridLayerName, (e) => {
            if (e.features.length > 0) {
                const feature = e.features[0];
                onHexbinClick(feature);
            }
        });
    }

    static updateTemperatureHexbinLayer(map, temperatureLayer, showLayer, hexGridGeoJson) {
        const hexGridSourceName = temperatureLayer.layer_info.source_name;
        const hexGridLayerName = temperatureLayer.layer_info.layer_name;
        const hexGridOutlineLayerName = temperatureLayer.layer_info.outline_layer_name;

        if (map.current.getSource(hexGridSourceName)) {
            if (showLayer) {
                map.current.getSource(hexGridSourceName).setData(hexGridGeoJson);
                map.current.setLayoutProperty(hexGridLayerName, 'visibility', 'visible');
                map.current.setLayoutProperty(hexGridOutlineLayerName, 'visibility', 'visible');
            } else {
                map.current.setLayoutProperty(hexGridLayerName, 'visibility', 'none');
                map.current.setLayoutProperty(hexGridOutlineLayerName, 'visibility', 'none');
            }

            const colorRange = temperatureLayer.layer_info.color_range;

            // hexgrid layer
            map.current.setPaintProperty(hexGridLayerName,
                'fill-color', [
                'interpolate',
                ['linear'],
                ['get', 'temperature'],
                ...colorRange
            ]
            );
        }
    }

    static initPointsLayer(map, pointsLayer) {
        const pointSourceName = pointsLayer.layer_name
        const pointsLayerName = pointsLayer.layer_name;

        const defaultFeatures = turf.featureCollection([]);

        // source
        map.current.addSource(pointSourceName, {
            type: 'geojson',
            data: defaultFeatures
        });

        // layer
        map.current.addLayer({
            id: pointsLayerName,
            source: pointSourceName,
            type: 'circle',
            paint: {
                'circle-radius': 2,
                'circle-color': '#FFEA00'
            }
        });
    }

    static updatePointsLayer(map, pointsLayer, showLayer, origGeojsonData) {
        const pointSourceName = pointsLayer.layer_name
        const pointsLayerName = pointsLayer.layer_name;

        if (map.current.getSource(pointSourceName)) {
            if (showLayer) {
                map.current.getSource(pointSourceName).setData(origGeojsonData);
                map.current.setLayoutProperty(pointsLayerName, 'visibility', 'visible');
            } else {
                map.current.setLayoutProperty(pointsLayerName, 'visibility', 'none');
            }
        }
    }

    static initExpandedPointsLayer(map, expandedPointsLayer) {
        const pointSourceName = expandedPointsLayer.source_name
        const pointsLayerName = expandedPointsLayer.layer_name;

        const defaultFeatures = turf.featureCollection([]);

        // source
        map.current.addSource(pointSourceName, {
            type: 'geojson',
            data: defaultFeatures
        });

        // layer
        map.current.addLayer({
            id: pointsLayerName,
            source: pointSourceName,
            type: 'circle',
            paint: {
                'circle-radius': 2,
                'circle-color': '#007cbf'
            }
        });

    }

    static updateExpandedPointsLayer(map, expandedPointsLayer, showLayer, geojsonData) {
        const pointSourceName = expandedPointsLayer.source_name;
        const pointsLayerName = expandedPointsLayer.layer_name;

        if (map.current.getSource(pointSourceName)) {
            if (showLayer) {
                map.current.getSource(pointSourceName).setData(geojsonData);
                map.current.setLayoutProperty(pointsLayerName, 'visibility', 'visible');
            } else {
                map.current.setLayoutProperty(pointsLayerName, 'visibility', 'none');
            }
        }
    }

    static initHeadingLayer(map, headingLayerInfo) {
        const headingSourceName = headingLayerInfo.layer_name
        const headingLayerName = headingLayerInfo.source_name;
        const iconName = headingLayerInfo.icon_name;
        const defaultFeatures = turf.featureCollection([]);

        // source
        map.current.addSource(headingSourceName, {
            type: 'geojson',
            data: defaultFeatures
        });

        // layer
        map.current.addLayer({
            id: headingLayerName,
            source: headingSourceName,
            type: 'symbol',
            layout: {
                'icon-image': iconName,
                'icon-allow-overlap': true,
                'icon-rotate': ['get', 'heading'],
                'icon-rotation-alignment': 'map', // rotation relative to the map's north
                'icon-size': 0.2
            }
        });

        map.current.on('mousemove', headingLayerName, (e) => {
            if (!e.features || e.features.length < 1) {
                return;
            }

            const feature = e.features[0];
            const description = `Heading: ${feature.properties.heading}`;

            console.log(description);
        });
    }

    static updateHeadingLayer(map, headingLayer, showLayer, origGeojsonData) {
        const headingSourceName = headingLayer.layer_name
        const headingLayerName = headingLayer.source_name;

        if (map.current.getSource(headingSourceName)) {
            if (showLayer) {
                map.current.getSource(headingSourceName).setData(origGeojsonData);
                map.current.setLayoutProperty(headingLayerName, 'visibility', 'visible');
            } else {
                map.current.setLayoutProperty(headingLayerName, 'visibility', 'none');
            }
        }
    }

    static initRectangleLayer(map, rectLayerInfo) {
        const rectSourceName = rectLayerInfo.source_name;
        const rectLayerName = rectLayerInfo.layer_name;
        const defaultFeatures = turf.featureCollection([]);

        // source
        map.current.addSource(rectSourceName, {
            type: 'geojson',
            data: defaultFeatures
        });

        map.current.addLayer({
            'id': rectLayerName,
            'type': 'line',
            'source': rectSourceName,
            'layout': {},
            'paint': {
                'line-color': '#0000ff',
                'line-width': 1,
            }
        });
    }

    static updateRectangleMapLayer(map, rectLayer, showLayer, rectGeojson) {
        const rectangleSourceName = rectLayer.source_name;
        const rectangleLayerName = rectLayer.layer_name;

        if (map.current.getSource(rectangleSourceName)) {
            if (showLayer) {
                map.current.getSource(rectangleSourceName).setData(rectGeojson);
                map.current.setLayoutProperty(rectangleLayerName, 'visibility', 'visible');
            } else {
                map.current.setLayoutProperty(rectangleLayerName, 'visibility', 'none');
            }
        }
    }

    static initRollerIconLayer(map, rollerIconLayerInfo) {
        const iconSourceName = rollerIconLayerInfo.source_name;
        const iconLayerName = rollerIconLayerInfo.layer_name;
        const iconName = rollerIconLayerInfo.icon_name;
        const defaultFeatures = turf.featureCollection([]);

        // init layer
        if (!map.current.getSource(iconSourceName)) {
            // source
            map.current.addSource(iconSourceName, {
                type: 'geojson',
                data: defaultFeatures
            });

            // layer
            map.current.addLayer({
                id: iconLayerName,
                source: iconSourceName,
                type: 'symbol',
                layout: {
                    'icon-image': iconName,
                    'icon-allow-overlap': true,
                    'icon-rotate': ['get', 'heading'],
                    'icon-rotation-alignment': 'map', // rotation relative to the map's north
                    //'icon-size': 0.5
                    'icon-size': [
                        "interpolate", ["linear"], ["zoom"],
                        // zoom levels and corresponding icon sizes
                        16, 0,
                        19, 1,
                        20, 1.35,
                        23, 10
                    ]
                }
            });
        }
    }

    static updateRollerIconLayer(map, rollerIconLayer, iconInfo) {
        const iconSourceName = rollerIconLayer.source_name;
        const iconLayerName = rollerIconLayer.layer_name;

        const showLayer = !!(iconInfo && iconInfo.coordinates)

        let iconGeojsonData = null;

        if (showLayer) {
            iconGeojsonData = turf.point(iconInfo.coordinates, {
                heading: iconInfo.heading
            });
        }

        if (map.current.getSource(iconSourceName)) {
            if (showLayer) {
                map.current.setLayoutProperty(iconLayerName, 'visibility', 'visible');

                if (iconGeojsonData) {
                    map.current.getSource(iconSourceName).setData(iconGeojsonData);
                }
            } else {
                map.current.setLayoutProperty(iconLayerName, 'visibility', 'none');
            }
        }
    }

    static initProjectIconLayer(map, projectIconLayerInfo) {
        const iconSourceName = projectIconLayerInfo.source_name;
        const iconLayerName = projectIconLayerInfo.layer_name;
        const iconName = projectIconLayerInfo.icon_name;
        const defaultFeatures = turf.featureCollection([]);

        // init layer
        if (!map.current.getSource(iconSourceName)) {
            // source
            map.current.addSource(iconSourceName, {
                type: 'geojson',
                data: defaultFeatures
            });

            // layer
            map.current.addLayer({
                id: iconLayerName,
                source: iconSourceName,
                type: 'symbol',
                layout: {
                    'icon-image': iconName,
                    'icon-allow-overlap': true,
                    //'icon-size': 0.5
                    'icon-size': [
                        "interpolate", ["linear"], ["zoom"],
                        // zoom levels and corresponding icon sizes
                        1, 0.1,
                        15, 0.1,
                        15.01, 0,
                    ]
                }
            });
        }
    }

    static updateProjectIconLayer(map, projectIconInfo, iconInfo) {
        const iconSourceName = projectIconInfo.source_name;
        const iconLayerName = projectIconInfo.layer_name;

        const showLayer = !!(iconInfo && iconInfo.coordinates)

        let iconGeojsonData = null;

        if (showLayer) {
            iconGeojsonData = turf.point(iconInfo.coordinates);
        }

        if (map.current.getSource(iconSourceName)) {
            if (showLayer) {
                map.current.setLayoutProperty(iconLayerName, 'visibility', 'visible');

                if (iconGeojsonData) {
                    map.current.getSource(iconSourceName).setData(iconGeojsonData);
                }
            } else {
                map.current.setLayoutProperty(iconLayerName, 'visibility', 'none');
            }
        }
    }

    static initCoreSampleIconLayer(map, coreSampleIconLayerInfo) {
        const iconSourceName = coreSampleIconLayerInfo.source_name;
        const iconLayerName = coreSampleIconLayerInfo.layer_name;
        const iconName = coreSampleIconLayerInfo.icon_name;
        const defaultFeatures = turf.featureCollection([]);

        // init layer
        if (!map.current.getSource(iconSourceName)) {
            // source
            map.current.addSource(iconSourceName, {
                type: 'geojson',
                data: defaultFeatures
            });

            // layer
            map.current.addLayer({
                id: iconLayerName,
                source: iconSourceName,
                type: 'symbol',
                layout: {
                    'icon-image': iconName,
                    'icon-allow-overlap': true,
                    'icon-rotate': ['get', 'heading'],
                    'icon-rotation-alignment': 'map', // rotation relative to the map's north
                    //'icon-size': 0.5
                    'icon-size': [
                        "interpolate", ["linear"], ["zoom"],
                        // zoom levels and corresponding icon sizes
                        10, 0.045,
                        20, 0.06,
                        23, 0.15,
                    ]
                }
            });
        }
    }

    static updateCoreSampleIconLayer(map, coreSampleIconLayer, coreSampleGeojson) {
        const iconSourceName = coreSampleIconLayer.source_name;
        const iconLayerName = coreSampleIconLayer.layer_name;

        const showLayer = !!coreSampleGeojson;

        if (map.current.getSource(iconSourceName)) {
            if (showLayer) {
                map.current.setLayoutProperty(iconLayerName, 'visibility', 'visible');

                if (coreSampleGeojson) {
                    map.current.getSource(iconSourceName).setData(coreSampleGeojson);
                }
            } else {
                map.current.setLayoutProperty(iconLayerName, 'visibility', 'none');
            }
        }
    }

    static initHoverPopups(map, popup, layerNames) {
        for (let i = 0; i < layerNames.length; i++) {
            const layerName = layerNames[i];

            map.current.on('mousemove', layerName, (e) => {
                if (!e.features || e.features.length < 1) {
                    return;
                }

                // Change the cursor style as a UI indicator.
                map.current.getCanvas().style.cursor = 'pointer';

                const feature = e.features[0];
                const centroid = turf.centroid(feature).geometry.coordinates;

                // check if the feature has a property named "lab_data"
                const isCoreSample = feature.properties.hasOwnProperty('lab_data');

                let description = '';

                if (isCoreSample) {
                    description = this.getCoreSampleHoverContent(feature);
                } else {
                    description = this.getHexbinHoverContent(feature);
                }

                // Ensure that if the map is zoomed out such that multiple
                // copies of the feature are visible, the popup appears
                // over the copy being pointed to.
                while (Math.abs(e.lngLat.lng - centroid[0]) > 180) {
                    centroid[0] += e.lngLat.lng > centroid[0] ? 360 : -360;
                }

                // Populate the popup and set its coordinates
                // based on the feature found.
                popup.setLngLat(centroid).setHTML(description).addTo(map.current);
            });

            map.current.on('mouseleave', layerName, () => {
                map.current.getCanvas().style.cursor = '';
                popup.remove();
            });
        }
    }

    static getHexbinHoverContent = (feature) => {
        const density = feature.properties.density.toFixed(1);
        const temp = feature.properties.temperature.toFixed(0);
        const speed = feature.properties.speed.toFixed(1);

        const tdStyled = `<td style="padding: 2px 6px; font-size: 0.9rem; background-color: transparent;">`;

        const description = `
              <table style="background-color: transparent;">
                <tr><td colspan="2" style="text-align: center; color: #515151; font-weight: 600;">Hexbin</td></tr>
                <tr>${tdStyled}<b>Pass Count</b>  </td> ${tdStyled}${feature.properties.pass_count}</td></tr>
                <tr>${tdStyled}<b>Density</b>     </td> ${tdStyled}${density} <small>%MTD</small></td></tr>
                <tr>${tdStyled}<b>Temperature</b> </td> ${tdStyled}${temp} <small>\u00B0F</small></td></tr>
                <tr>${tdStyled}<b>Speed</b>       </td> ${tdStyled}${speed} <small>mph</small></td></tr>
              </table>
            `;

        return description;
    }

    static getCoreSampleHoverContent = (feature) => {
        const name = feature.properties.core;
        const estimate = feature.properties.estimate ? feature.properties.estimate.toFixed(1) : '-';
        const labData = feature.properties.lab_data;

        const description = `
          <table>
            <tr><td colspan="2" style="text-align: center; color: #515151; font-weight: 600;">Core Sample</td></tr>
            <tr><td><b>Name</b> </td> <td>${name}</td></tr>
            <tr><td><b>RTD</b>  </td> <td>${estimate}</td></tr>
            <tr><td><b>Core</b> </td> <td>${labData}</td></tr>
          </table>
        `;

        return description;
    }
}
