import React from 'react';
import { Box, Typography, Paper, Divider } from '@mui/material';

const EquipmentDisplay = ({ equipment }) => {
    return (
        <Box>
            <Paper elevation={1} sx={{ p: 2, maxWidth: 300 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    <Typography variant="h6" align="center">Equipment</Typography>
                </Box>
                <Divider sx={{ my: 1 }} />
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                    <Typography variant="subtitle2">ID:</Typography>
                    <Typography variant="body2">{equipment.equipmentID ? equipment.equipmentID : '-'}</Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                    <Typography variant="subtitle2">Manufacturer:</Typography>
                    <Typography variant="body2">{equipment.manufacturer ? equipment.manufacturer : '-'}</Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                    <Typography variant="subtitle2">Model:</Typography>
                    <Typography variant="body2">{equipment.model ? equipment.model : '-'}</Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="subtitle2">Drum Size:</Typography>
                    <Typography variant="body2">{equipment.drumSize ? equipment.drumSize : '-'}</Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                    <Typography variant="subtitle2">Description:</Typography>
                    <Typography variant="body2">{equipment.description ? equipment.description : '-'}</Typography>
                </Box>
            </Paper>
        </Box>
    );
};

export default EquipmentDisplay;