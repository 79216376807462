// MUI
import {
    Box, Typography,
    Dialog, DialogTitle, DialogContent
} from '@mui/material';
// components
import DataKeyForm from './DataKeyForm';
// util
import { formatUtcToLocalTz } from "src/@core/utils/dateTimeFormatter";

// ----------------------------------------------------------------------

export default function DataKeyFormDialog({ open, onClose, dataKey }) {
    const onSaved = () => {
        onClose();
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth={true}
            maxWidth="sm"
        >
            <DialogTitle sx={{ mb: 1 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'tart' }}>
                    <Box>
                        <Typography  sx={{ color: 'text.primary' }}>{!!dataKey ? 'Update' : 'Add'} Data Key</Typography>
                        {dataKey?.keyValue && 
                            <Typography component="div" variant="subtitle1" sx={{ color: 'text.secondary' }}>
                                {dataKey.equipmentID ? dataKey.equipmentID : '-'}
                            </Typography>
                        }
                    </Box>
                    {dataKey && (
                        <Box>
                            <Typography component="div" variant="subtitle1" sx={{ textAlign: 'right' }}>
                                {dataKey.keyValue}
                            </Typography>
                            <Typography variant="body2" component="div" sx={{ color: 'text.secondary', textAlign: 'right' }}>
                                {dataKey.timestamp ? formatUtcToLocalTz(dataKey.timestamp, 'MM/dd/yyyy hh:mm:ss') : '-'}
                            </Typography>
                        </Box>
                    )}
                </Box>
            </DialogTitle>
            <DialogContent>
                <Box sx={{ pt: 1 }}>
                    <DataKeyForm dataKey={dataKey} onSaved={onSaved} />
                </Box>
            </DialogContent>
        </Dialog>
    );
}
