import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

const DialogBox = ({
  title,
  maxWidth,
  children,
  isOpen,
  handleClose,
  hide = false,
  hideButtons = false,
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={maxWidth}
    >
      <DialogTitle sx={{ mb: 1 }}>{title}</DialogTitle>

      <DialogContent>
        <Box sx={{ pt: 1 }}>{children}</Box>
      </DialogContent>

      <DialogActions hidden={hideButtons}>
        <Button onClick={handleClose}>
          Cancel
        </Button>
        <Button onClick={handleClose} hidden={hide}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogBox;
