import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { useDispatch, useSelector } from "react-redux";
import { ToastSuccess, ToastError } from "../components/Toast";
import { axiosApiInstance } from '../@core/utils/axios';

const initialState = {
  customerLoading: false,
  allcustomer: [],
  customerById: [],
  formSuccess: [],
  error: ''
};

export const getAllCustomerList = createAsyncThunk(
  'customer/getAllCustomerList',
  async (loginIds) => {
    try {
      const { data } = await axiosApiInstance.get(`/Customer/GetAllCustomer?companyId=${loginIds.companyId}&userId=${loginIds.userId}`);
      return data;
    } catch (error) {
      ToastError(error.response.data.messageKey);
    }
  }
);

export const getCustomerById = createAsyncThunk(
  'customer/getCustomerById',
  async (ids) => {
    try {
      const { data } = await axiosApiInstance.get(`/api/v2/customers/${ids.rowId}`);
      return data;
    } catch (error) {
      ToastError(error.response.data.messageKey);
    }
  }
);

export const postCustomerList = createAsyncThunk(
  'customer/postCustomerList',
  async (data) => {
    try {
      const loginId = JSON.parse(localStorage.getItem("loginRes"));
      const response = await axiosApiInstance.post(`/Customer/Save?userId=${loginId.userId}`, data);
      return response;
    } catch (error) {
      ToastError(error.response.data.messageKey);
    }
  }
);

// V2

export const getCustomerByIdV2 = createAsyncThunk(
  'customer/getCustomerByIdV2',
  async (id) => {
    try {
      const { data } = await axiosApiInstance.get(`/api/v2/customers/${id}`);
      return data;
    } catch (error) {
      ToastError(error.response.data.messageKey);
    }
  }
);

export const createCustomer = createAsyncThunk(
  'customer/createCustomer',
  async (data) => {
    try {
      const url = `/api/v2/customers`;
      const response = await axiosApiInstance.post(url, data);
      return response;
    } catch (error) {
      ToastError(error.response.data.messageKey);
    }
  }
);

export const updateCustomer = createAsyncThunk(
  'customer/updateCustomer',
  async (data) => {
    try {
      const url = `/api/v2/customers/${data.customerSK}`;
      const response = await axiosApiInstance.put(url, data);
      return response;
    } catch (error) {
      ToastError(error.response.data.messageKey);
    }
  }
);

export const customerSlice = createSlice({
  name: 'customer',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getCustomerByIdV2.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCustomerByIdV2.fulfilled, (state, action) => {
        state.loading = false;
        state.customerById = action.payload || [];
      })
      .addCase(getCustomerByIdV2.rejected, (state, action) => {
        state.loading = false;
        state.customerById = [];
      })

      .addCase(createCustomer.pending, (state) => {
        state.loading = true;
      })
      .addCase(createCustomer.fulfilled, (state, action) => {
        state.loading = false;
        state.formSuccess = action.payload;
      })
      .addCase(createCustomer.rejected, (state, action) => {
        state.loading = false;
        state.formSuccess = [];
        state.formSuccess = action.error.message;
      })

      .addCase(updateCustomer.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateCustomer.fulfilled, (state, action) => {
        state.loading = false;
        state.formSuccess = action.payload;
      })
      .addCase(updateCustomer.rejected, (state, action) => {
        state.loading = false;
        state.formSuccess = [];
        state.formSuccess = action.error.message;
      })

      // V1
      .addCase(getAllCustomerList.pending, (state) => {
        state.customerLoading = true;
      })
      .addCase(getAllCustomerList.fulfilled, (state, action) => {
        state.customerLoading = false;
        const { result } = action.payload;
        state.allcustomer = result?.customers || [];
      })
      .addCase(getAllCustomerList.rejected, (state, action) => {
        state.customerLoading = false;
        state.allcustomer = [];
        state.allcustomer = action.error.message;
      })
      .addCase(getCustomerById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCustomerById.fulfilled, (state, action) => {
        state.loading = false;
        const { result } = action.payload;
        state.customerById = result?.customers || [];
      })
      .addCase(getCustomerById.rejected, (state, action) => {
        state.loading = false;
        state.customerById = [];
        state.customerById = action.error.message;
      })
      .addCase(postCustomerList.pending, (state) => {
        state.loading = true;
      })
      .addCase(postCustomerList.fulfilled, (state, action) => {
        state.loading = false;
        state.formSuccess = action.payload;
      })
      .addCase(postCustomerList.rejected, (state, action) => {
        state.loading = false;
        state.formSuccess = [];
        state.formSuccess = action.error.message;
      });
  },
});

export default customerSlice.reducer;
