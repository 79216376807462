// MUI
import {
    Box,
    Dialog, DialogTitle, DialogContent
} from '@mui/material';
// components
import IpcForm from './IpcForm';

// ----------------------------------------------------------------------

export default function IpcFormDialog({ open, onClose, ipc, systemInfoId }) {
    const onSaved = () => {
        onClose();
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth={true}
            maxWidth="sm"
        >
            <DialogTitle sx={{ mb: 1 }}>{!!ipc ? 'Update' : 'Add'} IPC</DialogTitle>
            <DialogContent>
                <Box sx={{ pt: 1 }}>
                    <IpcForm ipc={ipc} onSaved={onSaved} systemInfoId={systemInfoId} />
                </Box>
            </DialogContent>
        </Dialog>
    );
}
