import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ToastSuccess, ToastError } from "../components/Toast";
import { axiosApiInstance } from '../@core/utils/axios';

const initialState = {
    loading: false,
    systemInfos: [],
    systemInfoById: {},
    error: '',
    loading: false,
};

export const getAllSystemInfo = createAsyncThunk(
    'systemInfo/getAllSystemInfo',
    async (data = { companyId: null }) => {
        try {
            let url = `/api/v2/systems?`;

            if (data.companyId) {
                url += `companyId=${data.companyId}&`;
            }

            url = url.slice(0, -1); // Remove the trailing '&' or '?'

            const response = await axiosApiInstance.get(url);
            return response;
        } catch (error) {
            ToastError(error.message);
        }
    }
);

export const createSystemInfo = createAsyncThunk(
    'systemInfo/createSystemInfo',
    async (data) => {
        try {
            const response = await axiosApiInstance.post(`/api/v2/systems`, data);
            return response;
        } catch (error) {
            ToastError(error.message);
        }
    }
);

export const getSystemInfoById = createAsyncThunk(
    'systemInfo/getSystemInfoById',
    async (id) => {
        try {
            const url = `/api/v2/systems/${id}`;
            const response = await axiosApiInstance.get(url);
            return response;
        } catch (error) {
            ToastError(error.message);
        }
    }
);

export const clearSystemInfoById = createAsyncThunk(
    'systemInfo/clearSystemInfoById',
    async () => {
        return {};
    }
);

export const updateSystemInfo = createAsyncThunk(
    'systemInfo/updateSystemInfo',
    async (updateData) => {
        try {
            const { id, data } = updateData;
            const url = `/api/v2/systems/${id}`;
            const response = await axiosApiInstance.put(url, data);
            return response;
        } catch (error) {
            ToastError(error.message);
        }
    }
);

export const assignEquipment = createAsyncThunk(
    'systemInfo/assignEquipment',
    async (updateData) => {
        try {
            const { id, data } = updateData;
            const url = `/api/v2/systems/${id}/assign-equipment`;
            const response = await axiosApiInstance.post(url, data);
            return response;
        } catch (error) {
            ToastError(error.message);
        }
    }
);

export const systemInfoSlice = createSlice({
    name: 'systemInfo',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getAllSystemInfo.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllSystemInfo.fulfilled, (state, action) => {
                state.loading = false;
                state.systemInfos = action.payload.data;
            })
            .addCase(getAllSystemInfo.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            .addCase(createSystemInfo.pending, (state) => {
                state.loading = true;
            })
            .addCase(createSystemInfo.fulfilled, (state, action) => {
                state.loading = false;
                state.systemInfoById = action.payload.data;
                state.systemInfos.push(action.payload.data);
            })
            .addCase(createSystemInfo.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            .addCase(getSystemInfoById.pending, (state) => {
                state.loading = true;
            })
            .addCase(getSystemInfoById.fulfilled, (state, action) => {
                state.loading = false;
                state.systemInfoById = action.payload.data;
            })
            .addCase(getSystemInfoById.rejected, (state, action) => {
                state.loading = false;
                state.systemInfoById = {};
                state.error = action.error.message;
            })

            .addCase(clearSystemInfoById.pending, (state) => {
                state.loading = true;
            })
            .addCase(clearSystemInfoById.fulfilled, (state, action) => {
                state.loading = false;
                state.systemInfoById = {};
            })
            .addCase(clearSystemInfoById.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            .addCase(updateSystemInfo.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateSystemInfo.fulfilled, (state, action) => {
                state.loading = false;

                const updatedSystemInfo = action.payload.data;
                const index = state.systemInfos.findIndex((systemInfo) => systemInfo.id === updatedSystemInfo.id);

                if (index !== -1) {
                    state.systemInfos[index] = updatedSystemInfo;
                }
            })
            .addCase(updateSystemInfo.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            .addCase(assignEquipment.pending, (state) => {
                state.loading = true;
            })
            .addCase(assignEquipment.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(assignEquipment.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export default systemInfoSlice.reducer;
