import React from "react";
import { iconSet } from "src/@core/data/icons";
// MUI
import { Box, Card, CardContent, Grid } from "@mui/material";
// components
import PageBreadcrumbs from "src/components/PageBreadcrumbs";
import ProjectsTable from "./components/ProjectsTable";
import EquipmentTable from "./components/EquipmentTable";

export default function HomePage() {
    const breadcrumbs = [
        {route: "", label: "Home"}
    ];

    return (
        <Box sx={{ p: { md: 2 } }}>
            <PageBreadcrumbs pageName="Home" breadcrumbs={breadcrumbs} icon={iconSet.dashboard} />

            <Grid container spacing={3}>
                <Grid item xs={12} xxl={6}>
                    <Card className="h-100">
                        <CardContent>
                            <ProjectsTable />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} xxl={6}>
                    <Card className="h-100">
                        <CardContent>
                            <EquipmentTable />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    )
}