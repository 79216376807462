import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// redux
import { useDispatch, useSelector } from "react-redux";
import { getRoles, deleteRole } from "src/features/adminSlice";
// assets
import { iconSet } from "src/@core/data/icons";
// types
import { PermissionTypes } from "src/@core/data/permission";
// MUI 
import { 
    Dialog,
    DialogContent
} from "@mui/material";
// components
import { CircleLoading } from "src/components/loadingCircle";
import { ToastSuccess } from 'src/components/Toast';
import DialogBox from "src/components/DialogBox";
import RoleForm from "./RoleForm";
import RolePermissionsForm from "./RolePermissionsForm";
import ActionMenu from "src/components/actionmenu";
import Table from "src/components/table/Table";
import PageBreadcrumbs from "src/components/PageBreadcrumbs";
import ConfirmDialog from "src/components/ConfirmDialog";
// util
import { permission } from "src/@core/data/permission";

// ----------------------------------------------------------------------

const adminOptions = ["Edit Role", "Edit Permissions", "Delete Role"];

const breadcrumbs = [
    { route: "/home", label: "Home" },
    { route: "/users", label: "Users" },
    { route: "", label: "Roles" },
];

// ----------------------------------------------------------------------

const RolePage = () => {
    const loginRes = JSON.parse(localStorage.getItem("loginRes"));
    const isSuperAdmin = loginRes?.superAdmin;
    const dispatch = useDispatch();
    const [editPermissionInfo, setEditPermissionInfo] = useState({ isOpen: false, roleId: null });
    const [createRoleInfo, setCreateRoleInfo] = useState({ isOpen: false });
    const [editRoleInfo, setEditRoleInfo] = useState({ isOpen: false, role: null });
    const { rolesloading, roles } = useSelector((state) => state.login);
    const { loading, roles: companyRoles } = useSelector((state) => state.companyRole);
    const [deleteConfirm, setDeleteConfirm] = useState({ open: false, role: null });
    const { adminSelectedCompany } = useSelector((state) => state.company);

    const canEditRoles = roles.userCompanyRolePermissionInfo.some(
        (rolscheck) =>
          rolscheck.permissionName === PermissionTypes.Roles && rolscheck.isWrite
      ) || isSuperAdmin;

    let hideColumn = ['companyRoleSK'];
    // Remove the action column if the user cannot edit roles
    if (!canEditRoles) {
      hideColumn = ['companyRoleSK', 'Action'];
    }

    const COLUMNS = [
        {
            Header: "Company Role Id",
            accessor: "companyRoleSK",
        },
        {
            Header: "Company",
            accessor: "companyName",
            Cell: ({ row }) => <div>{row.values.companyName ? row.values.companyName : "-"}</div>,
        },
        {
            Header: "Role",
            accessor: "roleName",
        },
        {
            Header: "Action",
            Cell: ({ row }) => {
                return (
                    <div>
                        {isSuperAdmin ? (
                            <ActionMenu
                                key="action-menu"
                                options={adminOptions}
                                handleMenuClick={(option) => handleItemMenuClick(row, option)}
                            />
                        ) : (
                            !rolesloading &&
                            roles.userCompanyRolePermissionInfo.map((rolscheck) => {
                                if (
                                    rolscheck.permissionName === permission.roles &&
                                    (rolscheck.isWrite || rolscheck.isDelete)
                                ) {
                                    let options = rolscheck.isDelete
                                        ? adminOptions
                                        : adminOptions.filter(option => option !== "Delete Role");
                                    return (
                                        <ActionMenu
                                            key="action-menu"
                                            options={options}
                                            handleMenuClick={(option) =>
                                                handleItemMenuClick(row, option)
                                            }
                                        />
                                    );
                                }
                                return null;
                            })
                        )}
                    </div>
                );
            },
        },
    ];

    useEffect(() => {
        if (isSuperAdmin && adminSelectedCompany?.companyId) {
          const data = { companyId: adminSelectedCompany.companyId };
          dispatch(getRoles(data));
        } else {
          dispatch(getRoles());
        }
    }, [dispatch, adminSelectedCompany]);

    const handleDialogOpen = () => {
        setCreateRoleInfo({ isOpen: true });
    };

    const handleItemMenuClick = (row, option) => {
        if (option === "Edit Role") {
            handleEditDialogOpen(row, option, true);
        } else if (option === "Delete Role") {
            setDeleteConfirm({ open: true, role: row.original });
        } else if (option === "Edit Permissions") {
            // const queryParams = new URLSearchParams({
            //     companySk: row.original.companySK,
            //     roleSk: row.original.companyRoleSK,
            // }).toString();

            // navigate(`/users/roles/permissions?${queryParams}`);

            setEditPermissionInfo({ isOpen: true, roleId: row.original.companyRoleSK });
        }
    };

    const submitDeleteRole = async (roleSK) => {
        dispatch(deleteRole(roleSK)).then((res) => {
          ToastSuccess('Role deleted');
          dispatch(getRoles());
        });
    };

    const handleEditDialogOpen = (row, e) => {
        setEditRoleInfo({ isOpen: true, role: row.original });
    };

    const onDeleteConfirmClose = (confirm) => {
        setDeleteConfirm({ open: false, role: null });

        if (confirm) {
            submitDeleteRole(deleteConfirm.role.companyRoleSK);
        }
    };

    return (
        <div>
            <div className="d-flex flex-column px-md-4 form-layout customer-form">
                <section className="d-flex mb-2 mt-2">
                    <div className="flex-grow-1">
                        <PageBreadcrumbs pageName="Roles" breadcrumbs={breadcrumbs} icon={iconSet.users} />
                    </div>
                    <div className="heading-right-btn">
                        {isSuperAdmin && (
                            <button
                                className="btn btn-primary ms-3"
                                onClick={handleDialogOpen}
                            >
                                Create Role
                            </button>
                        )}
                        {roles.userCompanyRolePermissionInfo.map((rolscheck) => {
                            return (rolscheck.permissionName === permission.roles && rolscheck.isWrite && (
                                <button
                                    className="btn btn-primary ms-3"
                                    onClick={handleDialogOpen}
                                >
                                    Create Role
                                </button>
                            ));
                        })}
                    </div>
                </section>

                <div className="card">
                    <div className="card-body">
                        {loading ? (
                            <div>
                                <CircleLoading />
                            </div>
                        ) : (
                            <Table
                                COLUMNS={COLUMNS}
                                parseData={companyRoles}
                                hideColumn={hideColumn}
                                heading={"Roles"}
                            />
                        )}
                    </div>
                </div>
            </div>
            <DialogBox
                title="Create Role"
                isOpen={createRoleInfo.isOpen}
                handleClose={() => setCreateRoleInfo({ isOpen: false })}
                hideButtons={true}
            >
                <RoleForm
                    role={null}
                    handleClose={() => setCreateRoleInfo({ isOpen: false })}
                />
            </DialogBox>

            <DialogBox
                title="Edit Role"
                isOpen={editRoleInfo.isOpen}
                handleClose={() => setEditRoleInfo({ isOpen: false, role: null })}
                hideButtons={true}
            >
                <RoleForm
                    role={editRoleInfo.role}
                    handleClose={() => setEditRoleInfo({ isOpen: false, role: null })}
                />
            </DialogBox>

            <Dialog
                open={editPermissionInfo.isOpen}
                onClose={() => setEditPermissionInfo({ isOpen: false, roleId: null })}
                fullWidth={true}
            >
                <DialogContent>
                    <RolePermissionsForm
                        roleId={editPermissionInfo.roleId}
                        handleClose={() => setEditPermissionInfo({ isOpen: false, roleId: null })}
                    />
                </DialogContent>
            </Dialog>

            <ConfirmDialog
                open={deleteConfirm.open}
                title="Delete Role"
                message="Are you sure you want to delete this user role?"
                onClose={onDeleteConfirmClose}
                maxWidth="xs"
            />
        </div>
    );
};

export default RolePage;
