import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
// MUI
import {
    Box, Button, TextField, Grid, Divider
} from "@mui/material";
// redux
import { clearSystemInfoById, getSystemInfoById, createSystemInfo, updateSystemInfo } from "src/features/systemInfoSlice";
import { deleteSensor } from "src/features/sensorSlice";
// components
import { ToastSuccess, ToastError } from "src/components/Toast";
import ConfirmDialog from "src/components/ConfirmDialog";
import IpcFormDialog from "./IpcFormDialog";
import IpcDisplay from "./IpcDisplay";
import SensorFormDialog from "./SensorFormDialog";
import SensorDisplay from "./SensorDisplay";
import LoadingButton from "src/components/LoadingButton";

// ----------------------------------------------------------------------

const defaultFormValues = {
    name: "",
    systemId: "",
};

// ----------------------------------------------------------------------

export default function EquipmentForm({ id = null }) {
    const loginRes = JSON.parse(localStorage.getItem("loginRes"));
    const isSuperAdmin = loginRes?.superAdmin;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { systemInfoById } = useSelector((state) => state.systemInfo);
    const [submitting, setSubmitting] = useState(false);
    const [ipcFormDialog, setIpcFormDialog] = useState({ open: false, ipc: null, systemInfoId: null });
    const [sensorFormDialog, setSensorFormDialog] = useState({ open: false, sensor: null, systemInfoId: null });
    const [deleteSensorConfirm, setDeleteSensorConfirm] = useState({ open: false, sensorId: null });
    const canEditSystems = !!isSuperAdmin;

    // react hook form
    const {
        register,
        handleSubmit,
        reset,
        control,
        formState: { errors },
        setValue
    } = useForm({
        defaultValues: defaultFormValues
    });

    useEffect(() => {
        if (!systemInfoById) {
            reset();
        } else {
            const formData = {
                name: systemInfoById.name || "",
                systemId: systemInfoById.systemId || "",
            };

            reset(formData);
        }
    }, [systemInfoById]);

    useEffect(() => {
        if (!id) {
            dispatch(clearSystemInfoById());
            reset();
        } else {
            dispatch(getSystemInfoById(id));
        }
    }, [dispatch, id]);

    const openIpcFormDialog = (ipc) => {
        setIpcFormDialog({ open: true, ipc: ipc, systemInfoId: systemInfoById.id });
    }

    const onIpcFormClose = () => {
        console.log('onIpcFormClose id: ', id);

        setIpcFormDialog({ open: false, ipc: null, systemInfoId: null });
        dispatch(getSystemInfoById(id));
    }

    const openSensorFormDialog = (sensor) => {
        setSensorFormDialog({ open: true, sensor: sensor, systemInfoId: systemInfoById.id });
    }

    const onSensorFormClose = () => {
        setSensorFormDialog({ open: false, sensor: null, systemInfoId: null });
        dispatch(getSystemInfoById(id));
    }

    const deleteSensorById = async (sensorId) => {
        dispatch(deleteSensor(sensorId)).then((res) => {
            if (res.payload) {
                ToastSuccess("Sensor deleted!");
                dispatch(getSystemInfoById(id));
            } else {
                ToastError("Error deleting sensor");
            }
        });
    };

    const confirmDeleteSesnor = (sensor) => {
        setDeleteSensorConfirm({ open: true, sensorId: sensor.id });
    }

    const closeDeleteSensorConfirm = (confirm) => {
        if (confirm) {
            deleteSensorById(deleteSensorConfirm.sensorId);
        }

        setDeleteSensorConfirm({ open: false, sensorId: null });
    }

    const onSubmit = (data) => {
        setSubmitting(true);

        if (systemInfoById?.id) {
            const updateData = {
                id: systemInfoById.id,
                data: {
                    ...data,
                    equipmentSK: systemInfoById.equipmentSK,
                }
            };

            dispatch(updateSystemInfo(updateData)).then((res) => {
                ToastSuccess("System saved");
                setSubmitting(false);
            });
        } else {
            dispatch(createSystemInfo(data)).then((res) => {
                setSubmitting(false);
                ToastSuccess("System saved");

                const url = `/systems/form?id=${res.payload.data.id}`;
                navigate(url, { replace: true });
            });
        }
    }

    return (
        <Box>
            {canEditSystems && (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={4}>
                            <Controller
                                name="name"
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <TextField {...field} fullWidth error={!!error} helperText={error?.message} label="Name" />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Controller
                                name="systemId"
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <TextField {...field} fullWidth error={!!error} helperText={error?.message} label="system ID" />
                                )}
                            />
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                            <LoadingButton
                                type="submit"
                                variant="contained"
                                color="primary"
                                loading={submitting}
                                sx={{ width: '110px' }}
                            >
                                Save
                            </LoadingButton>
                        </Box>
                    </Grid>
                </form>
            )}

            {!!systemInfoById?.id && (
                <Divider sx={{ mt: 2, mb: 2 }} />
            )}

            {!!systemInfoById.id && (
                <Box>
                    <Box sx={{ mb: 2 }}>
                        {(systemInfoById && systemInfoById?.ipc) && (
                            <IpcDisplay ipc={systemInfoById?.ipc} onEdit={openIpcFormDialog} />
                        )}
                        {(isSuperAdmin && systemInfoById && !systemInfoById?.ipc) && (
                            <Button sx={{ width: '130px' }} onClick={() => openIpcFormDialog(null)} variant="contained" color="primary">
                                Add IPC
                            </Button>
                        )}
                    </Box>

                    <Box>
                        {isSuperAdmin && (
                            <Button sx={{ width: '130px' }} onClick={() => openSensorFormDialog(null)} variant="contained" color="primary">
                                Add Sensor
                            </Button>
                        )}

                        {(systemInfoById && systemInfoById?.sensors) && (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', mt: 2 }}>
                                {systemInfoById?.sensors.map((sensor, index) => (
                                    <Box key={index} sx={{ mr: 2, mb: 2 }}>
                                        <SensorDisplay sensor={sensor} onEdit={openSensorFormDialog} onDelete={confirmDeleteSesnor} />
                                    </Box>
                                ))}
                            </Box>
                        )}
                    </Box>
                </Box>
            )}

            <IpcFormDialog
                open={ipcFormDialog.open}
                onClose={onIpcFormClose}
                ipc={ipcFormDialog.ipc}
                systemInfoId={ipcFormDialog.systemInfoId}
            />

            <SensorFormDialog
                open={sensorFormDialog.open}
                onClose={onSensorFormClose}
                sensor={sensorFormDialog.sensor}
                systemInfoId={sensorFormDialog.systemInfoId}
            />

            <ConfirmDialog
                open={deleteSensorConfirm.open}
                title="Delete Sensor"
                message="Are you sure you want to delete this sensor? This cannot be undone."
                onClose={closeDeleteSensorConfirm}
                maxWidth="xs"
            />
        </Box >
    );
};

