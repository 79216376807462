import { useState, useEffect } from "react";
import { axiosApiInstance } from 'src/@core/utils/axios';
// redux
import { useDispatch, useSelector } from "react-redux";
import { updateProfile } from 'src/features/loginSlice';
// forms
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
// MUI
import {
    Grid, TextField, Box
} from "@mui/material";
// components
import { ToastSuccess, ToastError } from 'src/components/Toast';
import LoadingButton from 'src/components/LoadingButton';

// ----------------------------------------------------------------------

export default function ProfileForm() {
    const [submitting, setSubmitting] = useState(false);
    const dispatch = useDispatch();
    const { loginData, loading } = useSelector((state) => state.login);

    useEffect(() => {
        // update the form with the user data
        if (loginData) {
            const { firstName, lastName } = loginData;
            setValue('firstName', firstName);
            setValue('lastName', lastName);
        }
    }, [loginData]);

    const defaultFormValues = {
        firstName: '',
        lastName: '',
    }

    // useMemo to rebuild the form when the user prop changes
    const formSchema = Yup.object().shape({
        firstName: Yup.string().required("First Name is required"),
        lastName: Yup.string().required("Last Name is required"),
    });

    const { register, handleSubmit, reset, control, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(formSchema),
        defaultValues: defaultFormValues,
    });

    const onSubmit = async (data) => {
        setSubmitting(true);

        try {
            //await axiosApiInstance.put(`/api/v2/profile`, data);
            await dispatch(updateProfile(data));
            ToastSuccess("Profile saved!");
        } catch (error) {
            const msg = error.response.data.messageKey || 'An unknown error occurred';
            ToastError(msg);
        }

        setSubmitting(false);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <Grid container spacing={2}>
                <Grid item sm={12} md={6} lg={4}>
                    <Controller
                        name="firstName"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <TextField {...field} fullWidth error={!!error} helperText={error?.message} label="First Name" />
                        )}
                    />
                </Grid>
                <Grid item sm={12} md={6} lg={4}>
                    <Controller
                        name="lastName"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <TextField {...field} fullWidth error={!!error} helperText={error?.message} label="Last Name" />
                        )}
                    />
                </Grid>

                <Grid item sm={12} md={12} lg={8}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <LoadingButton
                            type="submit"
                            variant="contained"
                            color="primary"
                            loading={loading}
                            sx={{ width: '110px' }}
                        >
                            Save
                        </LoadingButton>
                    </Box>
                </Grid>
            </Grid>
        </form>
    );
};

