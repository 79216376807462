import { useState } from "react";
// components
import PageBreadcrumbs from "src/components/PageBreadcrumbs";
import ProfileForm from "./components/ProfileForm";
import ResetPasswordForm from "./components/ResetPasswordForm";
// MUI
import {
  Card, CardContent,
  Tabs, Tab, Box
} from "@mui/material";
// assets
import { iconSet } from "src/@core/data/icons";

const breadcrumbs = [
  { route: "/Home", label: "Home" },
  { route: "", label: "Account" },
];

export default function AccountPage() {
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div className="d-flex flex-column px-md-4 form-layout customer-form">
      <PageBreadcrumbs pageName="Account" breadcrumbs={breadcrumbs} icon={iconSet.users} />
      <Card sx={{ pt: 2 }}>
        <CardContent>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={tabValue} onChange={handleTabChange} aria-label="Account Tabs">
                <Tab label="Profile" />
                <Tab label="Password" />
              </Tabs>
            </Box>
            <CustomTabPanel value={tabValue} index={0}>
              <ProfileForm />
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={1}>
              <ResetPasswordForm user={null} />
            </CustomTabPanel>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`account-tabpanel-${index}`}
      aria-labelledby={`account-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </Box>
  );
}