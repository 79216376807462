import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ToastSuccess, ToastError } from "../components/Toast";
import { axiosApiInstance } from "../@core/utils/axios";

const initialState = {
    iotListLoading: false,
    iotRowLoading: false,
    availableIotListLoading: false,
    allIotdeviceByCompanyLoading: false,
    allIotdevice: [],
    allIotdeviceByCompany: [],
    iotdeviceById: [],
    formSuccess: [],
    saveSuccess:[],
    error: "",
};

export const getAllIOTDevices = createAsyncThunk(
    "iot/getAllIOTDevices",
    async (loginIds) => {
        try {
            const { data } = await axiosApiInstance.get(
                `/IOT/GetAllIOTDevices?companyId=${loginIds.companyId}&userId=${loginIds.userId}`
            );
            return data;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);


export const getAvailableIOTDevices = createAsyncThunk(
    "iot/getAvailableIOTDevices",
    async (loginIds) => {
        try {
            const { data } = await axiosApiInstance.get(
                `/IOT/GetAvailableIOTDevices?userId=${loginIds.userId}`
            );
            return data;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);

export const getIOTDevicesByCompany = createAsyncThunk(
    "iot/getIOTDevicesByCompany",
    async (loginIds) => {
        try {
            console.log("in");
            const { data } = await axiosApiInstance.get(
                `/IOT/GetAllIOTDevice?companyId=${loginIds.companyId}&userId=${loginIds.userId}`
            );
            return data;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);

export const getIOTDeviceById = createAsyncThunk(
    "iot/getIOTDeviceById",
    async (loginIds) => {
        try {
            const { data } = await axiosApiInstance.get(
                `/IOT/GetIOTDevice?iotDeviceId=${loginIds.rowId}&companyId=${loginIds.companyId}&userId=${loginIds.userId}`
            );
            return data;
        } catch (error) {
            ToastError(error.response.data.messageKey);

        }
    }
);

export const postIotDevice = createAsyncThunk(
    "iot/postIotDevice",
    async (data) => {
        try {
            const loginId = JSON.parse(localStorage.getItem("loginRes"));
            const response = await axiosApiInstance.post(
                `/IOT/Save?userId=${loginId.userId}`,
                data
            );
            return response;
        } catch (error) {
            ToastError(error.response.data.messageKey);

        }
    }
);

export const postAvailableIotDevice = createAsyncThunk(
    "iot/postAvailableIotDevice",
    async (data) => {
        try {
            const loginId = JSON.parse(localStorage.getItem("loginRes"));
            const response = await axiosApiInstance.post(
                `/IOT/SaveAvailableIoT?userId=${loginId.userId}`,
                data
            );
            return response;
        } catch (error) {
            ToastError(error.response.data.messageKey);

        }
    }
);



export const postIotConfig = createAsyncThunk(
    "iot/postIotConfig",
    async (data) => {
        try {
            const loginId = JSON.parse(localStorage.getItem("loginRes"));
            const response = await axiosApiInstance.post(
                `/IOT/SaveIOT?userId=${loginId.userId}`,
                data
            );
            return response;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);

export const iotDeviceSlice = createSlice({
    name: "iot",
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getAllIOTDevices.pending, (state) => {
                state.iotListLoading = true;
            })
            .addCase(getAllIOTDevices.fulfilled, (state, action) => {
                state.iotListLoading = false;
                const { result } = action.payload;
                state.allIotdevice = result?.iotDevices || [];
            })
            .addCase(getAllIOTDevices.rejected, (state, action) => {
                state.iotListLoading = false;
                state.allIotdevice = [];
                state.allIotdevice = action.error.message;
            })
            .addCase(getAvailableIOTDevices.pending, (state) => {
                state.availableIotListLoading = true;
            })
            .addCase(getAvailableIOTDevices.fulfilled, (state, action) => {
                state.availableIotListLoading = false;
                const { result } = action.payload;
                state.availableIotdevice = result?.iotDevices || [];
            })
            .addCase(getAvailableIOTDevices.rejected, (state, action) => {
                state.availableIotListLoading = false;
                state.availableIotdevice = [];
                state.availableIotdevice = action.error.message;
            })        
            .addCase(getIOTDeviceById.pending, (state) => {
                state.iotRowLoading = true;
            })
            .addCase(getIOTDeviceById.fulfilled, (state, action) => {
                state.iotRowLoading = false;
                const { result } = action.payload;
                state.iotdeviceById = result?.iotDevices || [];
            })
            .addCase(getIOTDeviceById.rejected, (state, action) => {
                state.iotRowLoading = false;
                state.iotdeviceById = [];
                state.iotdeviceById = action.error.message;
            })
            .addCase(postIotDevice.fulfilled, (state, action) => {
                state.formSuccess = action.payload;
            })
            .addCase(postIotDevice.rejected, (state, action) => {
                state.formSuccess = [];
                state.formSuccess = action.error.message;
            })
            .addCase(postAvailableIotDevice.fulfilled, (state, action) => {
                state.saveSuccess = action.payload;
            })
            .addCase(postAvailableIotDevice.rejected, (state, action) => {
                state.saveSuccess = [];
                state.saveSuccess = action.error.message;
            })        
            .addCase(getIOTDevicesByCompany.pending, (state) => {
                state.allIotdeviceByCompanyLoading = true;
            })
            .addCase(getIOTDevicesByCompany.fulfilled, (state, action) => {
                state.allIotdeviceByCompanyLoading = false;
                const { result } = action.payload;
                state.allIotdeviceByCompany = result?.iotDevices || [];
            })
            .addCase(getIOTDevicesByCompany.rejected, (state, action) => {
                state.allIotdeviceByCompanyLoading = false;
                state.allIotdeviceByCompany = [];
                state.allIotdeviceByCompany = action.error.message;
            });
    },
});

export default iotDeviceSlice.reducer;
