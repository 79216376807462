import axios from 'axios';

export const axiosApiInstance = axios.create({
    baseURL: `${process.env.REACT_APP_APIURL}`
  });

// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
  async config => {
    const token = localStorage.getItem("token");
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
  },
  error => {
    Promise.reject(error)
});