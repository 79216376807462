import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { ToastSuccess, ToastError } from "../components/Toast";
import { axiosApiInstance } from "../@core/utils/axios";

export const DEFAULT_LOGIN_IDS = {
    companyId: "",
    userId: "",
    companyName: ""
};

const initialState = {
    loading: false,
    rolesloading: false,
    loginData: {},
    loginState: false,
    loggedIn: false,
    loginIds: DEFAULT_LOGIN_IDS,
    switchCompany: false,
    token: '',
    roles: [],
    error: ''
};

export const postLogin = createAsyncThunk(
    'login/postLogin',
    async (login) => {
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_APIURL}/Login/Login`, login);
            return data;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);

export const updateProfile = createAsyncThunk(
    'login/updateProfile',
    async (postData) => {
        try {
            const { data } = await axiosApiInstance.put(`${process.env.REACT_APP_APIURL}/api/v2/profile`, postData);
            return data;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);

export const rolesPermission = createAsyncThunk(
    'login/rolesPermission',
    async (ids) => {
        try {
            const token = localStorage.getItem("loginToken");
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
            const { data } = await axios.get(`${process.env.REACT_APP_APIURL}/User/GetRoleAndPermission?userId=${ids.userId}&companyid=${ids.companyId}`, config);
            localStorage.setItem("token", data.token);
            return data;
        } catch (error) {
            ToastError("Roles Permission " + error.response.data.messageKey);
        }
    }
);

// V2

export const loginV2 = createAsyncThunk(
    'login/loginV2',
    async (data) => {
        try {
            const url = `/api/v2/auth/login`;
            const repsonse = await axiosApiInstance.post(url, data);
            return repsonse;
        } catch (error) {
            if(error?.response?.data?.error) {
                ToastError(error.response.data.error);
            } else {
                ToastError(error.message);
            }
        }
    }
);

export const logout = createAsyncThunk(
    'login/logout',
    async (data) => {
        try {
            const url = `/api/v2/auth/logout`;
            const repsonse = await axiosApiInstance.post(url, data);
            return repsonse;
        } catch (error) {
            if(error?.response?.data?.error) {
                ToastError(error.response.data.error);
            } else {
                ToastError(error.message);
            }
        }
    }
);

export const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        setLoginIds: (state, action) => {
            state.loginIds = action.payload;
        },
        setLoggedIn: (state, action) => {
            state.loggedIn = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            // V2
            .addCase(loginV2.pending, (state) => {
                state.rolesloading = true;
                state.loading = true;
            })
            .addCase(loginV2.fulfilled, (state, action) => {
                state.loading = false;
                state.rolesloading = false;
                state.loginData = action.payload;
                state.roles = action.payload?.data || {};
            })
            .addCase(loginV2.rejected, (state, action) => {
                state.loading = false;
                state.rolesloading = false;
                state.loginData = {};
                state.roles = {};
                state.loginState = false;
                state.loginState = action.error.message;
                state.error = action.error.message;
            })

            .addCase(logout.pending, (state) => {
                state.rolesloading = true;
                state.loading = true;
            })
            .addCase(logout.fulfilled, (state, action) => {
                state.loading = false;
                state.rolesloading = false;
                state.loginData = {};
                state.loggedIn = false;
                state.roles = {};
            })
            .addCase(logout.rejected, (state, action) => {
                state.loading = false;
                state.rolesloading = false;
                state.loginState = false;
                state.loginState = action.error.message;
                state.error = action.error.message;
            })

            // V1
            .addCase(postLogin.pending, (state) => {
                 state.loading = true;
            })
            .addCase(postLogin.fulfilled, (state, action) => {
                state.loading = false;
                const loginObj = action.payload || [];
                state.loginData = loginObj
                state.loginState = loginObj && loginObj?.messageKey === "success" ? true : false;
                state.token = loginObj && loginObj?.token;
                state.switchCompany = loginObj && loginObj?.companyInfo?.length > 1 ? true : false;
            })
            .addCase(postLogin.rejected, (state, action) => {
                state.loading = false;
                state.loginData = {};
                state.loginState = false;
                state.loginState = action.error.message;
            }).addCase(updateProfile.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateProfile.fulfilled, (state, action) => {
                state.loginData = {
                    ...state.loginData,
                    firstName: action.payload.firstName,
                    lastName: action.payload.lastName,
                };
                state.loading = false;
            })
            .addCase(updateProfile.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(rolesPermission.pending, (state) => {
                state.rolesloading = true;
            })
            .addCase(rolesPermission.fulfilled, (state, action) => {
                state.rolesloading = false;
                const rolesPayload = action.payload || [];
                state.roles = rolesPayload;
            })
            .addCase(rolesPermission.rejected, (state, action) => {
                state.rolesloading = false;
                state.roles = [];
            })
    },
});

export const { setLoginIds, setLoggedIn } = loginSlice.actions;

export const selectLoggedIn = (state) => state.login.loggedIn;

export default loginSlice.reducer;
