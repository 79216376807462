import { useState, useEffect, useRef } from "react";
import { axiosApiInstance } from "src/@core/utils/axios";
import { Link } from "react-router-dom";
// MUI
import { useTheme } from '@mui/material/styles';
import styled from '@mui/material/styles/styled';
import { Box, Typography, CircularProgress } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import { ExpandMore } from '@mui/icons-material';
// components
import ProjectDroppable from './ProjectDroppable';
import DataKeyFormDialog from "src/pages/data-key/components/DataKeyFormDialog";
import DataKeyCard from "./DataKeyCard";
// util
import { formatDate } from "src/@core/utils/dateTimeFormatter";
import { formatUtcToLocalTz } from "src/@core/utils/dateTimeFormatter";

// ----------------------------------------------------------------------

const AccordionHeader = styled(Box)(({ theme }) => ({
    cursor: 'pointer',
    userSelect: 'none',
    padding: '8px',
    borderRadius: '3px 3px 0 0',
    boxShadow: 'rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.31) 0px 0px 1px',
    color: 'rgb(33, 37, 41)',

    background: 'rgb(255, 255, 255)',
    '&:hover': {
        background: 'rgb(250 250 250 )',
    },

    height: '49px',
    display: 'flex',
    alignItems: 'center',

    flex: '0 1 auto',
    lineHeight: '0',
}));

const AccordionInnerHeader = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
}));

// ----------------------------------------------------------------------

const ProjectAccordion = ({ projectWithEquipment, onEquipmentClick, onProjectClick }) => {
    const contentRef = useRef(null);
    const theme = useTheme();
    const [isOpen, setIsOpen] = useState(false);
    const [contentHeight, setContentHeight] = useState(100);
    const [projectDetails, setProjectDetails] = useState({});
    const [loading, setLoading] = useState(false);
    const [curAccordionStyle, setCurAccordionStyle] = useState(getAccordionStyle(0));
    const [dataKeyDialog, setDataKeyDialog] = useState({ open: false, dataKey: null });
    const [selectedDate, setSelectedDate] = useState(null);

    useEffect(() => {
        if (projectWithEquipment?.projectSK && isOpen) {
            getProjectDetails(projectWithEquipment.projectSK);
        }
    }, [projectWithEquipment, isOpen]);

    const getProjectDetails = async (id) => {
        // Using the Redux store to query the project details was causing 
        //   issues with the project in parent components.
        //   Querying the project details directly from the API instead.
        setLoading(true);
        setCurAccordionStyle(getAccordionStyle(100));
        const { data } = await axiosApiInstance.get(`/api/v2/projects/${id}`);
        setLoading(false);

        if (data.dataKeys) {
            data.dataKeys.forEach(dataKey => {
                dataKey.local_timestamp = formatUtcToLocalTz(dataKey.timestamp, 'MM/dd/yyyy hh:mm:ss');
            });
        }

        setProjectDetails(data);
    }

    const toggleAccordion = () => {
        if (isOpen) {
            setCurAccordionStyle(getAccordionStyle(0));
        }

        setIsOpen(!isOpen);
    }

    useEffect(() => {
        const updateHeight = () => {
            if (contentRef.current) {
                setContentHeight(contentRef.current.scrollHeight);
            }
        };

        updateHeight();

        window.addEventListener('resize', updateHeight);

        return () => {
            window.removeEventListener('resize', updateHeight);
        };
    }, [isOpen, loading, projectDetails]);

    useEffect(() => {
        const newHeight = isOpen ? contentHeight : 0;

        setCurAccordionStyle(getAccordionStyle(newHeight));
    }, [isOpen, loading, contentHeight]);

    const onEditDataKey = (dataKey) => {
        setDataKeyDialog({ open: true, dataKey: dataKey });
    };

    const closeDataKeyDialog = () => {
        setDataKeyDialog({ open: false, dataKey: null });
        getProjectDetails(projectWithEquipment.projectSK);
    }

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const clearDate = () => {
        setSelectedDate(null);
    };

    const filteredDataKeys = projectDetails?.dataKeys?.filter(dataKey => {
        if (!selectedDate) return true;
        const dataKeyDate = new Date(dataKey.local_timestamp);
        return dataKeyDate.toDateString() === selectedDate.toDateString();
    });

    return (
        <Box>
            <Box>
                <ProjectDroppable projectWithEquipment={projectWithEquipment} droppableId={projectWithEquipment.projectSK} onEquipmentClick={onEquipmentClick} />
                <AccordionHeader onClick={toggleAccordion}>
                    <AccordionInnerHeader>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box>
                                <Box component="div">
                                    <Link
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            onProjectClick(projectWithEquipment)
                                        }}
                                        variant="body2"
                                    >
                                        {projectWithEquipment.projectName} <EditIcon sx={{ height: '0.8em' }} />
                                    </Link>
                                </Box>
                                <Typography variant="caption" sx={{ color: 'grey.600' }}>
                                    {projectWithEquipment.startDate ? formatDate(projectWithEquipment.startDate, "yyyy-MM-dd'T'HH:mm:ss", 'MM/dd') : '-'}
                                    {" - "}
                                    {projectWithEquipment.endDate ? formatDate(projectWithEquipment.endDate, "yyyy-MM-dd'T'HH:mm:ss", 'MM/dd/yy') : '-'}
                                </Typography>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <ExpandMore sx={getExpandIconStyle(isOpen)} />
                        </Box>
                    </AccordionInnerHeader>
                </AccordionHeader>
            </Box>
            <Box ref={contentRef} sx={curAccordionStyle}>
                <Box sx={{ display: 'flex', alignItems: 'center', p: 1, pt: 2, pl: 2 }}>
                    <DatePicker
                        label="Filter by Date"
                        value={selectedDate}
                        onChange={handleDateChange}
                        renderInput={(params) => <TextField {...params} />}
                    />
                    <IconButton
                        onClick={clearDate}
                        sx={{ ml: 1, color: theme.palette.error.main }}
                        disabled={!selectedDate}
                        aria-label="clear date"
                    >
                        <ClearIcon />
                    </IconButton>
                </Box>

                <Box sx={{ p: 1, display: 'flex', flexWrap: 'wrap' }}>
                    {loading && (
                        <Box sx={{ width: '100%', textAlign: 'center' }}>
                            <CircularProgress />
                        </Box>
                    )}

                    {!loading && filteredDataKeys?.map((dataKey, index) => (
                        <DataKeyCard key={index} dataKey={dataKey} onEdit={onEditDataKey} />
                    ))}

                    {!loading && filteredDataKeys?.length < 1 && (
                        <Box sx={{ width: '100%', textAlign: 'center', color: '#979797' }}>
                            No data keys found
                        </Box>
                    )}
                </Box>
            </Box>

            <DataKeyFormDialog
                open={dataKeyDialog.open}
                onClose={closeDataKeyDialog}
                dataKey={dataKeyDialog.dataKey}
            />
        </Box>
    );
};

export default ProjectAccordion;

const getAccordionStyle = (contentHeight) => ({
    maxHeight: `${contentHeight}px`,
    overflow: 'hidden',
    transition: 'max-height 0.25s ease-in-out',
    whiteSpace: 'normal',
    borderRadius: '0 0 3px 3px ',
    boxShadow: 'rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.31) 0px 0px 1px',
    color: 'rgb(33, 37, 41)',
    backgroundColor: '#f1f2f4',
});

const getExpandIconStyle = (isOpen) => ({
    transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
    transition: 'transform 0.3s ease-in-out'
});
