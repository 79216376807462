import styled from '@mui/material/styles/styled';
import { Box } from '@mui/material';

export const ProjectList = styled(Box)(({ theme }) => ({
  '& > *': { // target all direct children
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  }
}));

export const SystemStatusBadge = styled(Box)(({ theme, value }) => ({
  position: 'absolute',
  top: '2px',
  right: '2px',
  fontSize: '0.875rem',
  fontWeight: '500',
  borderRadius: '100%',
  backgroundColor: value > 0 ? '#ff9800' : value < 0 ? '#f44336' : '#4caf50',
  minWidth: '12px',
  minHeight: '12px',
  verticalAlign: 'middle',
  lineHeight: '21px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '12px',
}));
