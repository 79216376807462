export const setLoginStorage = (loginRes) => {
    localStorage.setItem("au", true);
    localStorage.setItem("token", loginRes.token);
    localStorage.setItem("loginToken", loginRes.token);
    localStorage.setItem("userName", loginRes.userName);

    const loginResStorage = {
      companyId: loginRes?.isSuperAdmin ? 0 : loginRes.companySk,
      userId: loginRes?.userId,
      companyName: loginRes?.isSuperAdmin ? "All" : loginRes.CompanyName ? loginRes.CompanyName : "",
      superAdmin: loginRes?.isSuperAdmin,
      userName: loginRes.userName,
      firstName: loginRes.firstName,
      lastName: loginRes.lastName,
    }

    localStorage.setItem("loginRes", JSON.stringify(loginResStorage));
}

export const removeLoginStorage = () => {
  localStorage.clear(); // just removing everything
}