import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
// redux
import { getUsers } from "src/features/userSlice";
// config
import { PermissionTypes } from "src/@core/data/permission";
// MUI
import { Box } from "@mui/material";
// components
import { CircleLoading } from "src/components/loadingCircle";
import ActionMenu from "src/components/actionmenu";
import Table from "src/components/table/Table";
import { ToastSuccess, ToastError } from 'src/components/Toast';
import ConfirmDialog from "src/components/ConfirmDialog";
import ViewUserDialog from "./ViewUserDialog";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

// ----------------------------------------------------------------------

const actionMenuOptions = ['View', 'Edit', 'Password']; // Hiding delete until we have soft delete or cascading delete or something
// const actionMenuOptions = ['View', 'Edit', 'Password', 'Delete'];

// ----------------------------------------------------------------------

const UserTable = ({ onUpdatePassword, onUpdateUser }) => {
  const dispatch = useDispatch();
  const loginRes = JSON.parse(localStorage.getItem("loginRes"));
  const isSuperAdmin = loginRes?.superAdmin;
  const [deleteConfirm, setDeleteConfirm] = useState({ open: false, user: null });
  const { usersLoading, users } = useSelector((state) => state.user);
  const { roles } = useSelector((state) => state.login);
  const { adminSelectedCompany } = useSelector((state) => state.company);
  const [viewUserDialog, setViewUserDialog] = useState({ open: false, user: null });

  const hideColumn = ['isSuperAdmin', 'userSK', 'Id'].filter((column) => {
    switch (column) {
      case 'userSK':
      case 'isSuperAdmin':
        return true;
      case 'isActive':
        return !isSuperAdmin;
      case 'companyName':
        return !isSuperAdmin;
      default:
        return false;
    }
  });

  const canEditUsers = isSuperAdmin || roles.userCompanyRolePermissionInfo.find(
    (role) => role.permissionName === PermissionTypes.User && role.isWrite
  );

  const canDeleteUsers = roles.userCompanyRolePermissionInfo.find(
    (role) => role.permissionName === PermissionTypes.User && role.isDelete
  );

  const actionOptions = actionMenuOptions.filter((option) => {
    if (isSuperAdmin) return true;

    switch (option) {
      case 'Edit':
        return canEditUsers;
      case 'Delete':
        return canDeleteUsers;
      case 'Password':
        return canEditUsers;
      case 'View':
        return true;
      default:
        return false;
    }
  });

  const COLUMNS = [
    {
      Header: "Id",
      accessor: "userSK",
      show: false,
    },
    {
      Header: "Super Admin",
      accessor: "isSuperAdmin",
      show: true,
    },
    {
      Header: "First Name",
      accessor: "firstName",
      Cell: ({ value }) => (value ? value : '-'),
      show: true,
    },
    {
      Header: "Last Name",
      accessor: "lastName",
      Cell: ({ value }) => (value ? value : '-'),
      show: true,
    },
    {
      Header: "Phone",
      accessor: "phone",
      Cell: ({ value }) => (value ? value : '-'),
      show: true,
    },
    {
      Header: "Company",
      accessor: "companyName",
      Cell: ({ value }) => (value ? value : '-'),
      show: isSuperAdmin,
    },
    {
      Header: "Role",
      accessor: "roleName",
      Cell: ({ value, row }) => (row.original.isSuperAdmin ? 'Super Admin' : (value ? value : '-')),
      show: true,
    },
    {
      Header: "Email",
      accessor: "email",
      show: true,
    },
    {
      Header: "Active",
      accessor: "isActive",
      Cell: ({ value, row }) => (
        value ? (
          <CheckIcon color="success" />
        ) : (
          <CloseIcon color="error" />
        )
      ),
      show: isSuperAdmin,
    },
    {
      Header: "Action",
      show: true,
      Cell: ({ row }) => {
        if (row.values.isSuperAdmin || row.values.userSK === loginRes?.userId) {
          return null; // return null to hide the ActionMenu
        }

        return (
          <ActionMenu
            options={actionOptions}
            handleMenuClick={(e) => handleActionMenu(row, e, true)}
          />
        );
      },
    },
  ];

  const filteredColumns = COLUMNS.filter((column) => column.show);

  const onDeleteConfirmClose = (confirm) => {
    if (confirm) {
      deleteUser(deleteConfirm.user);
    } else {
      setDeleteConfirm({ open: false, user: null });
    }
  };

  const handleActionMenu = (row, option) => {
    switch (option) {
      case 'Edit':
        onUpdateUser(row.original);
        break;
      case 'Delete':
        setDeleteConfirm({ open: true, user: row.original });
        break;
      case 'View':
        setViewUserDialog({ open: true, user: row.original });
        break;
      case 'Password':
        onUpdatePassword(row.original);
        break;
      default:
        break;
    }
  };

  const deleteUser = async (user) => {
    const url = `${process.env.REACT_APP_APIURL}/User/DeleteUser`;
    const token = localStorage.getItem('token');
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        userId: loginRes?.userId,
        userSK: user.userSK,
      },
    };

    try {
      await axios.get(url, config);
      setDeleteConfirm({ open: false, user: null });
      dispatch(getUsers());
      ToastSuccess('User Deleted!');
    } catch (error) {
      ToastError('An error occurred');
      console.error(error);
    }
  };

  useEffect(() => {
    if(isSuperAdmin && adminSelectedCompany.companyId) {
      dispatch(getUsers({ companyId: adminSelectedCompany.companyId }));
    } else {
      dispatch(getUsers());
    }
  }, [dispatch, adminSelectedCompany]);

  return (
    <Box>
      <div>
        {usersLoading ? (
          <div>
            <CircleLoading />
          </div>
        ) : (
          <Table
            COLUMNS={filteredColumns}
            parseData={users}
            hideColumn={hideColumn}
            heading={'Users'}
          />
        )}
      </div>
      
      <ViewUserDialog
        open={viewUserDialog.open}
        onClose={() => setViewUserDialog({ open: false, user: null })}
        user={viewUserDialog.user}
      />

      <ConfirmDialog 
        open={deleteConfirm.open}
        title="Delete User"
        message="Are you sure you want to delete this user?"
        onClose={onDeleteConfirmClose}
        maxWidth="xs"
      />
    </Box>
  );
};

export default UserTable;
