import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { axiosApiInstance } from "src/@core/utils/axios";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
// redux
import { useDispatch } from "react-redux";
import { getCompanyById, createCompany, updateCompany } from "src/features/companySlice";
// MUI
import {
    Box, TextField, Autocomplete, Card, CardContent, CardHeader,
    Grid, Button, Divider
} from "@mui/material";
import { iconSet } from "src/@core/data/icons";
// components
import PageBreadcrumbs from "src/components/PageBreadcrumbs";
import { ToastSuccess, ToastError } from "src/components/Toast";
import LoadingButton from 'src/components/LoadingButton';
import CustomerForm from "../customer/components/CustomerForm";
import ConfirmDialog from "src/components/ConfirmDialog";
import { usStates } from "src/@core/data/us-states"
import { usOnlyCountryList } from "src/@core/data/countries"

// ----------------------------------------------------------------------

const defaultFormValues = {
    companyName: "",
    companyId: "",
    email: "",
    address1: "",
    address2: "",
    country: "",
    state: "",
    city: "",
    zip: "",
    phone: "",
};

const breadcrumbs = [
    { route: "/home", label: "Home" },
    { route: "/companies", label: "Companies" },
    { route: "", label: "Detail" },
];

// ----------------------------------------------------------------------

export default function CompanyFormPage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [selectedCountry, setSelectedCountry] = useState("");
    const [countryValue, setCountryValue] = useState("");
    const [selectedState, setSelectedState] = useState("");
    const [stateValue, setStateValue] = useState("");
    const [submitting, setSubmitting] = useState(false);
    const [customers, setCustomers] = useState([]);
    const [companyId, setCompanyId] = useState(0);
    const [deleteCustomerConfirm, setDeleteCustomerConfirm] = useState({ open: false, customerId: null });

    const params = new URLSearchParams(window.location.search);
    const paramCompanyId = params.get("rid");

    const formSchema = Yup.object().shape({
        email: Yup.string().required("Email is required").email("Invalid email address"),
    });

    const {
        register,
        handleSubmit,
        reset,
        resetField,
        control,
        setValue,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(formSchema),
        defaultValues: defaultFormValues
    });

    const bindData = () => {
        if (paramCompanyId) {
            dispatch(getCompanyById(paramCompanyId)).then((res) => {
                reset(res.payload);
                setSelectedCountry(res.payload.country);
                setSelectedState(res.payload.state);
                setCompanyId(parseInt(res.payload.companySK));
                setCustomers(res.payload.customers);
            });
        } else {
            reset();
            setSelectedCountry(0);
        }
    };

    // effect runs when user state is updated
    useEffect(bindData, [dispatch, paramCompanyId]);

    const handleChangeState = (e, newValue) => {
        newValue === null ? setSelectedState('') : setSelectedState(newValue.key);
    };

    const handleChangeCountryValue = (e, newValue) => {
        setCountryValue(newValue);
    }

    const handleChangeCountry = (e, newValue) => {
        (newValue === null) ? setSelectedCountry('') : setSelectedCountry(newValue.code);
    };

    const onSubmit = (data, e) => {
        const postData = {
            ...data,
            state: selectedState,
            country: selectedCountry.toString()
        };

        setSubmitting(true);

        if (!paramCompanyId) {
            dispatch(createCompany(postData)).then((res) => {
                if (res.payload?.data?.companySK) {
                    ToastSuccess('Company saved');

                    // This won't actually update the browser history, but will update the URL
                    navigate(`/companies/form?rid=${res.payload.data.companySK}`);
                } else {
                    ToastError('An error occurred. Unexpected return value.');
                }

                setSubmitting(false);
            });
        } else {
            dispatch(updateCompany({ postData: postData, companySK: companyId })).then((res) => {
                if (res.payload?.data?.companySK) {
                    ToastSuccess('Company saved');
                }

                setSubmitting(false);
            });
        }
    };

    const deleteCustomer = async (customerId) => {
        const url = `/api/v2/customers/${customerId}`;

        try {
            await axiosApiInstance.delete(url);
            setDeleteCustomerConfirm({ open: false, customer: null });
            setCustomers(customers.filter((item) => item.customerSK !== customerId));
            ToastSuccess('Customer deleted!');
        } catch (error) {
            ToastError('An error occurred');
            console.error(error);
        }
    };

    const closeDeleteCustomerConfirm = (confirm) => {
        if (confirm) {
            console.log('deleteCustomerCOnfirm: ', deleteCustomerConfirm);
            if (deleteCustomerConfirm.customerId < 0) {
                // remove the dummy customer object
                setCustomers(customers.filter((item) => item.customerSK !== deleteCustomerConfirm.customerId));
                setDeleteCustomerConfirm({ open: false, customerId: null });
            } else {
                deleteCustomer(deleteCustomerConfirm.customerId);
            }
        } else {
            setDeleteCustomerConfirm({ open: false, customerId: null });
        }
    }

    const onDeleteClick = (customerId) => {
        setDeleteCustomerConfirm({ open: true, customerId: customerId });
    }

    const addCustomer = () => {
        // Add a dummy id to track the new customer record with
        let newCustomerId = Math.min(...customers.map(customer => customer.customerSK)) - 1;
        newCustomerId = Math.min(newCustomerId, -1);

        setCustomers([...customers, { customerSK: newCustomerId }])
    }

    const onCustomerSaved = (customerData, oldCustomerId=null) => {
        const existingCustomer = customers.find(customer => customer.customerSK === customerData.customerSK);
        const oldCustomer = customers.find(customer => customer.customerSK === oldCustomerId);

        if (existingCustomer) {
            setCustomers(customers.map(customer => customer.customerSK === customerData.customerSK ? customerData : customer));
        } else if(oldCustomer) {
            setCustomers(customers.map(customer => customer.customerSK === oldCustomerId ? customerData : customer));
        } else {
            setCustomers([...customers, customerData]);
        }
    }

    return (
        <Box className="d-flex flex-column px-md-4 form-layout">
            <Box className="d-flex mb-2 mt-2">
                <Box className="flex-grow-1">
                    <PageBreadcrumbs pageName="Company Details" breadcrumbs={breadcrumbs} icon={iconSet.company} />
                </Box>
            </Box>

            <Grid container spacing={2}>
                <Grid item sm={12} md={12} lg={12} xl={7}>
                    <Card>
                        <CardHeader title="Company" />
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item sm={12} md={6} lg={4}>
                                        <Controller
                                            name="companyName"
                                            control={control}
                                            render={({ field, fieldState: { error } }) => (
                                                <TextField {...field} fullWidth error={!!error} helperText={error?.message} label="Company Name" />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item sm={12} md={6} lg={4}>
                                        <Controller
                                            name="companyId"
                                            control={control}
                                            render={({ field, fieldState: { error } }) => (
                                                <TextField {...field} fullWidth error={!!error} helperText={error?.message} label="Company Id" />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item sm={12} lg={4} />

                                    <Grid item sm={12} md={6} lg={4}>
                                        <Controller
                                            name="email"
                                            control={control}
                                            render={({ field, fieldState: { error } }) => (
                                                <TextField type="email" {...field} fullWidth error={!!error} helperText={error?.message} label="Contact Email" />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item sm={12} md={6} lg={4} />
                                    <Grid item sm={12} lg={4} />

                                    <Grid item sm={12} md={6} lg={4}>
                                        <Controller
                                            name="address1"
                                            control={control}
                                            render={({ field, fieldState: { error } }) => (
                                                <TextField {...field} fullWidth error={!!error} helperText={error?.message} label="Address" />
                                            )}
                                        />
                                    </Grid>

                                    <Grid item sm={12} md={6} lg={4}>
                                        <Controller
                                            name="address2"
                                            control={control}
                                            render={({ field, fieldState: { error } }) => (
                                                <TextField {...field} fullWidth error={!!error} helperText={error?.message} label="Address 2" />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item sm={12} lg={4} />

                                    <Grid item sm={12} md={6} lg={4}>
                                        <Controller
                                            name="country"
                                            control={control}
                                            defaultValue=""
                                            render={({ field: { value, onChange }, fieldState }) => (
                                                <Autocomplete
                                                    noOptionsText="Select Value"
                                                    options={usOnlyCountryList}
                                                    getOptionLabel={option => option.name}
                                                    value={usOnlyCountryList.find((item) => item.code === selectedCountry) || null}
                                                    inputValue={countryValue}
                                                    onChange={(event, value) => {
                                                        handleChangeCountry(event, value);
                                                    }}
                                                    onInputChange={(event, newInputValue) => {
                                                        handleChangeCountryValue(event, newInputValue);
                                                    }}
                                                    renderInput={(params) =>
                                                        <TextField {...params}
                                                            variant="outlined"
                                                            label="Country"
                                                            {...register("country", {
                                                                validate: (value, formValues) => (
                                                                    countryValue != '' && !!usOnlyCountryList.find((item) => item.code === countryValue)
                                                                )
                                                            })} 
                                                        />
                                                    }
                                                >
                                                </Autocomplete>
                                            )}
                                        />
                                        {errors.country && (
                                            <div className="text-error">Please enter Country!</div>
                                        )}
                                    </Grid>

                                    <Grid item sm={12} md={6} lg={4}>
                                        <Controller
                                            name="state"
                                            control={control}
                                            defaultValue=""
                                            render={({ field: { value, onChange } }) => (
                                                <Autocomplete
                                                    noOptionsText={'Select Value'}
                                                    id="state"
                                                    options={usStates}
                                                    getOptionLabel={option => option.label}
                                                    value={usStates.find((item) => item.key === selectedState) || null}
                                                    inputValue={stateValue}
                                                    renderInput={(params) =>
                                                        <TextField {...params}
                                                            variant="outlined"
                                                            label="State"
                                                            {...register("state", {
                                                                validate: (value, formValues) => {
                                                                    return stateValue != '' && !!usStates.find((item) => item.key === stateValue);
                                                                }
                                                            })}
                                                        />}
                                                    onChange={(event, value) => {
                                                        handleChangeState(event, value);
                                                    }}
                                                    onInputChange={(event, newInputValue) => {
                                                        setStateValue(newInputValue)
                                                    }}
                                                />
                                            )}
                                        />
                                        {errors.state && (
                                            <div className="text-error">Please enter State!</div>
                                        )}
                                    </Grid>

                                    <Grid item sm={12} md={6} lg={4}>
                                        <Controller
                                            name="city"
                                            control={control}
                                            render={({ field, fieldState: { error } }) => (
                                                <TextField {...field} fullWidth error={!!error} helperText={error?.message} label="City" />
                                            )}
                                        />
                                    </Grid>

                                    <Grid item sm={12} md={6} lg={4}>
                                        <Controller
                                            name="zip"
                                            control={control}
                                            render={({ field, fieldState: { error } }) => (
                                                <TextField {...field} fullWidth error={!!error} helperText={error?.message} label="Zip" />
                                            )}
                                        />
                                    </Grid>

                                    <Grid item sm={12} md={6} lg={4}>
                                        <Controller
                                            name="phone"
                                            control={control}
                                            rules={{
                                                pattern: {
                                                    value: /^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/,
                                                    message: "Invalid phone number format"
                                                }
                                            }}
                                            render={({ field, fieldState: { error } }) => (
                                                <TextField {...field} fullWidth error={!!error} helperText={error?.message} label="Phone" />
                                            )}
                                        />
                                    </Grid>

                                    <Grid item sm={12}>
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <LoadingButton
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                loading={submitting}
                                                sx={{ width: '110px' }}
                                            >
                                                Save
                                            </LoadingButton>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </form>
                    </Card>
                </Grid>
                <Grid item sm={12} md={12} lg={12} xl={5}>
                    <Card>
                        <CardHeader
                            title="Customers"
                            action={
                                <Button variant="contained" color="primary" onClick={addCustomer} disabled={!paramCompanyId}>
                                    Add Customer
                                </Button>
                            }
                        />
                        <CardContent>
                            {customers.map((item, index) => (
                                <Box key={item.customerSK}>
                                    <CustomerForm companyId={companyId} customerId={item.customerSK} onDelete={onDeleteClick} onSaved={onCustomerSaved} />
                                    {index < customers.length - 1 && <Divider sx={{ my: 2 }} />}
                                </Box>
                            ))}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            <ConfirmDialog
                open={deleteCustomerConfirm.open}
                title="Delete Customer"
                message="Are you sure you want to delete this customer? This cannot be undone. This will not delete the company"
                onClose={closeDeleteCustomerConfirm}
                maxWidth="xs"
            />
        </Box>
    );
};
