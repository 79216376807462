import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { ToastSuccess, ToastError } from "../components/Toast";
import { axiosApiInstance } from '../@core/utils/axios';

const initialState = {
    loading: false,
    getRoleByIdLoading: false,
    permissionsLoading: false,
    allRolesByCompanyloading: false,
    companyRolePermissionLoading: false,
    associatedCompanyLoading: false,
    addAssociationCompanyLoading: false,
    allRolesByCompany: [],
    saveResByCompanyRoles: [],
    allPermissionByCompany: [],
    saveResByCompanyPermission: [],
    allRolesPermissionByCompany: [],
    associatedCompany: [],
    addAssociationCompany: [],

    permissions: [],
    roles: [],
    roleById: {},

    error: '',
    formSuccess: {},
};

export const getAllCompanyRoles = createAsyncThunk(
    'role/getAllCompanyRoles',
    async (loginIds) => {
        try {
            const { data } = await axiosApiInstance.get(`/CompanyRole/GetAllCompanyRole?companyId=${loginIds.companyId}&userId=${loginIds.userId}`);
            return data;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);

export const saveCompanyRoles = createAsyncThunk(
    'role/saveCompanyRoles',
    async (postdata) => {
        try {
            const { data } = await axiosApiInstance.post(`/CompanyRole/SaveRole?userId=${postdata.userId}`, postdata);
            return data;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);

export const getAllCompanyPermissions = createAsyncThunk(
    'role/getAllCompanyPermissions',
    async (loginIds) => {
        try {
            const { data } = await axiosApiInstance.get(`/CompanyPermission/GetAllCompanyPermission?companyId=${loginIds.companyId}&userId=${loginIds.userId}`);
            return data;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);

export const saveCompanyPermissions = createAsyncThunk(
    'role/saveCompanyPermissions',
    async (postdata) => {
        try {
            const { data } = await axiosApiInstance.post(`/CompanyPermission/SavePermission?userId=${postdata.userId}`, postdata);
            return data;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);

export const getCompanyRolePermissions = createAsyncThunk(
    'role/getCompanyRolePermissions',
    async (loginIds) => {
        try {
            const { data } = await axiosApiInstance.get(`/CompanyRole/GetCompanyRolePermission?companyId=${loginIds.companyId}&companyRoleId=${loginIds.companyRoleId}&permissionId=${loginIds.permissionId}&userId=${loginIds.userId}`);
            return data;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);

export const getAssociatedCompanyList = createAsyncThunk(
    'role/getAssociatedCompanyList',
    async (loginIds) => {
        try {
            const { data } = await axiosApiInstance.get(`/User/GetUserCompanyAssociation?userSK=${loginIds.rowId.userSK}&userId=${loginIds.userId}`);
            return data;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);

export const getAssociationCompanyAddList = createAsyncThunk(
    'role/getAssociationCompanyAddList',
    async (loginIds) => {
        try {
            const { data } = await axiosApiInstance.get(`/User/GetUserNonAssociatedCompany?userSK=${loginIds.rowId.userSK}&userId=${loginIds.userId}`);
            return data;
        } catch (error) {
            ToastError(error.response.data.messageKey);
        }
    }
);

// V2

export const getPermissions = createAsyncThunk(
    'role/getPermissions',
    async (data = { companyId: null }) => {
        try {
            let url = `/api/v2/permissions?`;

            if (data.companyId) {
                url += `companyId=${data.companyId}&`;
            }

            url = url.slice(0, -1); // Remove the trailing '&' or '?'

            const response = await axiosApiInstance.get(url);
            return response;
        } catch (error) {
            ToastError(error.message);
        }
    }
);

export const createPermission = createAsyncThunk(
    'role/createPermission',
    async (data) => {
        try {
            const response = await axiosApiInstance.post(`/api/v2/permissions`, data);
            return response;
        } catch (error) {
            ToastError(error.message);
        }
    }
);

export const updatePermission = createAsyncThunk(
    'role/updatePermission',
    async (updateData) => {
        try {
            const { id, data } = updateData;
            const url = `/api/v2/permissions/${id}`;
            const response = await axiosApiInstance.put(url, data);
            return response;
        } catch (error) {
            ToastError(error.message);
        }
    }
);

export const deletePermission = createAsyncThunk(
    'role/deletePermission',
    async (id) => {
        try {
            const url = `/api/v2/permissions/${id}`;
            const response = await axiosApiInstance.delete(url);
            return response;
        } catch (error) {
            ToastError(error.message);
        }
    }
);

export const getRoles = createAsyncThunk(
    'role/getRoles',
    async (data = { companyId: null }) => {
        try {
            let url = `/api/v2/roles?`;

            if (data.companyId) {
                url += `companyId=${data.companyId}&`;
            }

            url = url.slice(0, -1); // Remove the trailing '&' or '?'

            const response = await axiosApiInstance.get(url);
            return response;
        } catch (error) {
            ToastError(error.message);
        }
    }
);

export const getRoleById = createAsyncThunk(
    'role/getRoleById',
    async (id) => {
        try {
            const response = await axiosApiInstance.get(`/api/v2/roles/${id}`);
            return response;
        } catch (error) {
            ToastError(error.message);
        }
    }
);

export const createRole = createAsyncThunk(
    'role/createRole',
    async (data) => {
        try {
            const response = await axiosApiInstance.post(`/api/v2/roles`, data);
            return response;
        } catch (error) {
            ToastError(error.message);
        }
    }
);

export const updateRole = createAsyncThunk(
    'role/updateRole',
    async (updateData) => {
        try {
            const { id, data } = updateData;
            const url = `/api/v2/roles/${id}`;
            const response = await axiosApiInstance.put(url, data);
            return response;
        } catch (error) {
            ToastError(error.message);
        }
    }
);

export const updateRolePermissions = createAsyncThunk(
    'role/updateRolePermissions',
    async (updateData) => {
        try {
            const { id, data } = updateData;
            const url = `/api/v2/roles/${id}/permissions`;
            const response = await axiosApiInstance.put(url, data);
            return response;
        } catch (error) {
            ToastError(error.message);
        }
    }
);

export const deleteRole = createAsyncThunk(
    'role/deleteRole',
    async (id) => {
        try {
            const url = `/api/v2/roles/${id}`;
            const response = await axiosApiInstance.delete(url);
            return response;
        } catch (error) {
            ToastError(error.message);
        }
    }
);

export const companyRoleSlice = createSlice({
    name: 'role',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getPermissions.pending, (state) => {
                state.loading = true;
            })
            .addCase(getPermissions.fulfilled, (state, action) => {
                state.loading = false;
                state.permissions = action.payload.data;
            })
            .addCase(getPermissions.rejected, (state, action) => {
                state.loading = false;
                state.permissions = [];
                state.error = action.error.message;
            })

            .addCase(getRoleById.pending, (state) => {
                state.getRoleByIdLoading = true;
            })
            .addCase(getRoleById.fulfilled, (state, action) => {
                state.getRoleByIdLoading = false;
                state.roleById = action.payload;
            })
            .addCase(getRoleById.rejected, (state, action) => {
                state.getRoleByIdLoading = false;
                state.roleById = {};
                state.error = action.error.message;
            })

            .addCase(createPermission.pending, (state) => {
                state.loading = true;
            })
            .addCase(createPermission.fulfilled, (state, action) => {
                state.loading = false;
                state.formSuccess = action.payload?.data;
            })
            .addCase(createPermission.rejected, (state, action) => {
                state.loading = false;
                state.formSuccess = {};
                state.error = action.error.message;
            })

            .addCase(updatePermission.pending, (state) => {
                state.loading = true;
            })
            .addCase(updatePermission.fulfilled, (state, action) => {
                state.loading = false;
                state.formSuccess = action.payload;
            })
            .addCase(updatePermission.rejected, (state, action) => {
                state.loading = false;
                state.formSuccess = {};
                state.error = action.error.message;
            })

            .addCase(deletePermission.pending, (state) => {
                state.loading = true;
            })
            .addCase(deletePermission.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(deletePermission.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            .addCase(getRoles.pending, (state) => {
                state.loading = true;
            })
            .addCase(getRoles.fulfilled, (state, action) => {
                state.loading = false;
                state.roles = action.payload.data;
            })
            .addCase(getRoles.rejected, (state, action) => {
                state.loading = false;
                state.roles = [];
                state.error = action.error.message;
            })

            .addCase(createRole.pending, (state) => {
                state.loading = true;
            })
            .addCase(createRole.fulfilled, (state, action) => {
                state.loading = false;
                state.formSuccess = action.payload?.data;
            })
            .addCase(createRole.rejected, (state, action) => {
                state.loading = false;
                state.formSuccess = {};
                state.error = action.error.message;
            })

            .addCase(updateRole.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateRole.fulfilled, (state, action) => {
                state.loading = false;
                state.formSuccess = action.payload;
            })
            .addCase(updateRole.rejected, (state, action) => {
                state.loading = false;
                state.formSuccess = {};
                state.error = action.error.message;
            })

            .addCase(updateRolePermissions.pending, (state) => {
                state.permissionsLoading = true;
            })
            .addCase(updateRolePermissions.fulfilled, (state, action) => {
                state.permissionsLoading = false;
                state.formSuccess = action.payload;
            })
            .addCase(updateRolePermissions.rejected, (state, action) => {
                state.permissionsLoading = false;
                state.formSuccess = {};
                state.error = action.error.message;
            })

            .addCase(deleteRole.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteRole.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(deleteRole.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            // V1
            .addCase(getAllCompanyRoles.pending, (state) => {
                state.allRolesByCompanyloading = true;
            })
            .addCase(getAllCompanyRoles.fulfilled, (state, action) => {
                state.rallRolesByCompanyLoading = false;
                const { result } = action.payload;
                state.allRolesByCompany = result?.company || [];
            })
            .addCase(getAllCompanyRoles.rejected, (state, action) => {
                state.allRolesByCompanyloading = false;
                state.allRolesByCompany = [];
                state.allRolesByCompany = action.error.message;
            })
            .addCase(saveCompanyRoles.pending, (state) => {
                state.loading = true;
            })
            .addCase(saveCompanyRoles.fulfilled, (state, action) => {
                state.loading = false;
                const { result } = action.payload;
                state.saveResByCompanyRoles = result || [];
            })
            .addCase(saveCompanyRoles.rejected, (state, action) => {
                state.loading = false;
                state.saveResByCompanyRoles = [];
                state.saveResByCompanyRoles = action.error.message;
            })
            .addCase(getAllCompanyPermissions.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllCompanyPermissions.fulfilled, (state, action) => {
                state.loading = false;
                const { result } = action.payload;
                state.allPermissionByCompany = result?.company || [];
            })
            .addCase(getAllCompanyPermissions.rejected, (state, action) => {
                state.loading = false;
                state.allPermissionByCompany = [];
                state.allPermissionByCompany = action.error.message;
            })
            .addCase(saveCompanyPermissions.pending, (state) => {
                state.loading = true;
            })
            .addCase(saveCompanyPermissions.fulfilled, (state, action) => {
                state.loading = false;
                const { result } = action.payload;
                state.saveResByCompanyRoles = result || [];
            })
            .addCase(saveCompanyPermissions.rejected, (state, action) => {
                state.loading = false;
                state.saveResByCompanyRoles = [];
                state.saveResByCompanyRoles = action.error.message;
            })
            .addCase(getCompanyRolePermissions.pending, (state) => {
                state.companyRolePermissionLoading = true;
            })
            .addCase(getCompanyRolePermissions.fulfilled, (state, action) => {
                state.companyRolePermissionLoading = false;
                const { result } = action.payload;
                state.allRolesPermissionByCompany = result?.company || [];
            })
            .addCase(getCompanyRolePermissions.rejected, (state, action) => {
                state.companyRolePermissionLoading = false;
                state.allRolesPermissionByCompany = [];
                state.allRolesPermissionByCompany = action.error.message;
            })
            .addCase(getAssociatedCompanyList.pending, (state) => {
                state.associatedCompanyLoading = true;
            })
            .addCase(getAssociatedCompanyList.fulfilled, (state, action) => {
                state.associatedCompanyLoading = false;
                const { result } = action.payload;
                state.associatedCompany = result?.company || [];
            })
            .addCase(getAssociatedCompanyList.rejected, (state, action) => {
                state.associatedCompanyLoading = false;
                state.associatedCompany = [];
                state.associatedCompany = action.error.message;
            })
            .addCase(getAssociationCompanyAddList.pending, (state) => {
                state.addAssociationCompanyLoading = true;
            })
            .addCase(getAssociationCompanyAddList.fulfilled, (state, action) => {
                state.addAssociationCompanyLoading = false;
                const { result } = action.payload;
                state.addAssociationCompany = result?.company || [];
            })
            .addCase(getAssociationCompanyAddList.rejected, (state, action) => {
                state.addAssociationCompanyLoading = false;
                state.addAssociationCompany = [];
                state.addAssociationCompany = action.error.message;
            })
    },
});

export default companyRoleSlice.reducer;
